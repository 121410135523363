import { StringIndexResourceClient } from 'clients/StringIndexResourceClient';
import { Role, RoleProps } from 'models/Role';

export class RoleClient extends StringIndexResourceClient<Role, RoleProps> {
  constructor() {
    super(Role, 'roles');
  }
}

export const roleClient = new RoleClient();
