import {
  AppBar,
  createStyles,
  Divider,
  Drawer,
  Grid,
  IconButton,
  isWidthDown,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  withWidth,
  WithWidthProps
} from '@material-ui/core';
import {
  ArrowBack,
  Assignment,
  Category,
  Dashboard as DashboardIcon,
  ExitToApp,
  FormatListNumbered,
  Group,
  Receipt
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { roleClient } from 'clients/RoleClient';
import { userClient } from 'clients/UserClient';
import { Avatar } from 'components/Avatar';
import { ListItemLink } from 'components/ListItemLink';
import { Logo } from 'components/Logo';
import { Account } from 'components/views/Account';
import { AuditsWrapper } from 'components/views/Audits/AuditsWrapper';
import { AuditsLeaderboardView } from 'components/views/AuditsLeaderboardView';
import { Dashboard } from 'components/views/Dashboard';
import { NotFound } from 'components/views/NotFound';
import { PointsLeaderboardView } from 'components/views/PointsLeaderboardView';
import { Transactions } from 'components/views/Transactions';
import { TransactionTemplates } from 'components/views/TransactionTemplates';
import { Users } from 'components/views/Users';
import { VerifyEmail } from 'components/views/VerifyEmail';
import { Hide } from 'components/visibility/Hide';
import { Show } from 'components/visibility/Show';
import { config } from 'Config';
import { Role } from 'models/Role';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { handleUnauthorizedError } from 'RequestHelpers';

const drawerWidth = 240;
const useStyles = makeStyles((theme) =>
  createStyles({
    offset: theme.mixins.toolbar,
    root: {
      display: 'flex'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    menuButton: {
      marginRight: theme.spacing(1)
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerContainer: {
      overflow: 'auto'
    },
    drawerDivider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    closeButton: {
      marginBottom: theme.spacing(1)
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  })
);

function _ContentWrapper(props: WithWidthProps): React.ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const location = useLocation();
  const isMobileSize = isWidthDown('sm', props.width || 'xs');
  const [mountComplete, setMountComplete] = useState(false);
  const [currentUser, setCurrentUser] = useState(new User());
  const [drawerIsOpen, setDrawerIsOpen] = useState(true);
  const [drawerVariant, setDrawerVariant] = useState<'permanent' | 'temporary'>('permanent');

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setCurrentUser(await userClient.getCurrentUser({ query: { include: 'role' } }));
        Role.all = await roleClient.getAll();
        setMountComplete(true);
      } catch (e) {
        handleUnauthorizedError(e, history, snackbar);
      }
    })();

    document.title = config.appName;
  }, []);

  useEffect(() => {
    setDrawerVariant(isMobileSize ? 'temporary' : 'permanent');
    setDrawerIsOpen(!isMobileSize);
  }, [isMobileSize]);

  useEffect(() => {
    if (isMobileSize) {
      setDrawerIsOpen(false);
    }
  }, [location]);

  function toggleDrawer(): void {
    setDrawerIsOpen(!drawerIsOpen);
  }

  if (!mountComplete) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Show when={isMobileSize}>
            <IconButton className={classes.menuButton} color="inherit" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </Show>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Logo style={{ height: 35 }} />
            </Grid>
            <Grid item>
              <Typography variant="h6" component="h1">
                {config.appName}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={drawerVariant}
        open={drawerIsOpen}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={toggleDrawer}
      >
        <Hide when={isMobileSize}>
          <div className={classes.offset} />
        </Hide>
        <div className={classes.drawerContainer}>
          <List>
            <Show when={isMobileSize}>
              <ListItem className={classes.closeButton} onClick={toggleDrawer}>
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText primary="Close" />
              </ListItem>
            </Show>
            <ListItemLink primary="Dashboard" icon={<DashboardIcon />} to="/" selected="matchExactPath" />
            <Show when={currentUser.hasAccessLevel('supervisor')}>
              <ListItemLink primary="Users" icon={<Group />} to="/users" />
              <ListItemLink primary="Transactions" icon={<Receipt />} to="/transactions" />
            </Show>
            <Show when={currentUser.hasAccessLevel('admin')}>
              <ListItemLink primary="Categories" icon={<Category />} to="/categories" />
            </Show>
            <Show when={currentUser.hasAccessLevel('supervisor')}>
              <ListItemLink primary="Audits" to="/audits" icon={<Assignment />} />
            </Show>

            <ListItem>
              <ListItemText primary="Leaderboards" />
            </ListItem>
            <ListItemLink primary="Points" icon={<FormatListNumbered />} to="/leaderboards/points" />
            <ListItemLink primary="Audits" icon={<Assignment />} to="/leaderboards/audits" />

            <Divider className={classes.drawerDivider} />

            <ListItem>
              <ListItemText primary={currentUser.name} />
            </ListItem>
            {[
              {
                primary: 'Account',
                icon: <Avatar src={currentUser.avatar} size={3} />,
                to: '/account'
              },
              { primary: 'Log Out', icon: <ExitToApp />, to: '/logout' }
            ].map((item) => (
              <ListItemLink key={item.to} {...item} />
            ))}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.offset} />
        <Switch>
          <Route exact path="/">
            <Dashboard user={currentUser} />
          </Route>
          <Route path="/account">
            <Account currentUser={currentUser} onChange={setCurrentUser} />
          </Route>
          <Route path="/leaderboards/points">
            <PointsLeaderboardView />
          </Route>
          <Route path="/leaderboards/audits">
            <AuditsLeaderboardView />
          </Route>
          <Route path="/users/:userId">
            <Account currentUser={currentUser} />
          </Route>
          <Route path="/users">
            <Users currentUser={currentUser} />
          </Route>
          <Route path="/transactions">
            <Transactions currentUser={currentUser} isMobileSize={isMobileSize} />
          </Route>
          <Route path="/categories">
            <TransactionTemplates />
          </Route>
          <Route path="/audits">
            <AuditsWrapper currentUser={currentUser} />
          </Route>
          <Route path="/verify/:emailVerificationTokenId">
            <VerifyEmail user={currentUser} onChange={setCurrentUser} />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export const ContentWrapper = withWidth()(_ContentWrapper);
