import { DialogContent, DialogTitle, Divider, Grid, IconButton } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import { auditClient } from 'clients/AuditClient';
import { userClient } from 'clients/UserClient';
import { AvatarName } from 'components/AvatarName';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { SearchTextField } from 'components/SearchTextField';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { abortEffect, handleRequest } from 'RequestHelpers';

export interface AddAuditUserDialog extends DialogProps {
  currentUser: User;
  auditId?: number | null;
  onAdd?: () => any;
}

export function AddAuditUserDialog(props: AddAuditUserDialog): React.ReactElement {
  const snackbar = useSnackbar();
  const { currentUser, auditId, onAdd, ...dialogProps } = props;
  const { onClose, open } = dialogProps;
  const [searchName, setSearchName] = useState('');
  const [auditUserIds, setAuditUserIds] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    return abortEffect(async (signal) => {
      await handleRequest(async () => {
        if (auditId) {
          setAuditUserIds(
            (await auditClient.getUsers(auditId, { parse: false, signal })).map((user) => user.id as string)
          );
        }
      }, snackbar);
    });
  }, [auditId, open]);

  useEffect(() => {
    if (open) {
      return abortEffect(async (signal) => {
        await handleRequest(
          async () => {
            setUsers(await userClient.getAll({ query: { order: 'name', onlyKept: true }, signal }));
          },
          snackbar,
          {},
          { message: 'Error fetching users' }
        );
      });
    }
  }, [open]);

  async function addUser(userId: string): Promise<void> {
    await handleRequest(
      async () => {
        if (auditId) {
          await auditClient.addUser(auditId, userId);
          // snackbar.enqueueSnackbar('User added', { variant: 'success' });
          setAuditUserIds([...auditUserIds, userId]);

          if (onAdd) {
            onAdd();
          }
        } else {
          // Raise an error if auditId wasn't provided
          throw new Error('Audit ID not provided');
        }
      },
      snackbar,
      {},
      { message: 'Error adding user to audit' }
    );
  }

  let matchingUsers: User[] = [];
  let content;
  const trimmedSearch = searchName.trim().replace(/\s{2,}/g, ' ');
  const searchTokens = trimmedSearch
    .split(' ')
    .map((str) => str.toLowerCase())
    .filter((str, index, arr) => arr.indexOf(str) === index);

  matchingUsers = users.filter((user) => searchTokens.every((token) => user.name.toLowerCase().includes(token)));

  if (matchingUsers.length === 0) {
    content = 'No results';
  } else {
    content = (
      <Grid container direction="column" spacing={1}>
        {matchingUsers.map((user) => (
          <Grid item key={user.id}>
            <Grid container spacing={1} justify="space-between" alignItems="center">
              <Grid item>
                <AvatarName user={user} />
              </Grid>
              <Grid item>
                {auditUserIds.includes(user.id) ? (
                  <Check style={{ minWidth: 48 }} />
                ) : (
                  <IconButton disabled={user.id === currentUser.id} onClick={() => addUser(user.id)}>
                    <Add />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Dialog {...dialogProps}>
      <DialogCloseTitle onClose={onClose}>Add Users to Audit</DialogCloseTitle>
      <DialogTitle style={{ paddingTop: 0 }}>
        <SearchTextField
          label="Name"
          fullWidth
          autoFocus
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          onClear={() => setSearchName('')}
        />
      </DialogTitle>
      <Divider />
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
}
