import { Button, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';
import { transactionTemplateClient } from 'clients/TransactionTemplateClient';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { ErrorButton } from 'components/ErrorButton';
import { Show } from 'components/visibility/Show';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateInt, validateMaxLength, validateRequired } from 'FormHelpers';
import { TransactionTemplate, TransactionTemplateProps } from 'models/TransactionTemplate';
import { Checkboxes, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { handleRequest } from 'RequestHelpers';
import { closeDialog } from 'Utils';

export interface TransactionTemplateDialogData {
  name?: string;
  description?: string;
  value?: string;
  requiresComment: boolean;
}

// export interface ValidTemplateData {
//   name: string;
//   description?: string;
//   value: number;
//   requiresComment: boolean;
// }

export interface TransactionTemplateDialogProps extends DialogProps {
  templateId?: number | null;
  onCreate?: (template: TransactionTemplate) => any;
  onUpdate?: (template: TransactionTemplate) => any;
}

export function TemplateDialog(props: TransactionTemplateDialogProps): React.ReactElement {
  const { templateId, onCreate, onUpdate, ...dialogProps } = props;
  const { onClose } = dialogProps;
  const [template, setTemplate] = useState(new TransactionTemplate());
  const isNew = templateId == null;
  const snackbar = useSnackbar();
  const maxDescriptionLength = 200;

  useEffect(() => {
    handleRequest(async () => {
      let template: TransactionTemplate;

      if (templateId == null) {
        template = new TransactionTemplate();
      } else {
        template = await transactionTemplateClient.get(templateId);
      }

      setTemplate(template);
    }, snackbar).then();
  }, [templateId]);

  function validate(values: TransactionTemplateDialogData): ValidationErrors {
    const { name, description, value } = values;

    return cleanErrors({
      name: validateRequired(name),
      description: validateMaxLength(description, maxDescriptionLength),
      value: validateInt(value)
    });
  }

  function transformValues(values: TransactionTemplateDialogData): TransactionTemplateProps {
    const { value = '', description, ...props } = values;

    return {
      ...props,
      description: description, // force the key to exist even if it's undefined in values
      value: Number.parseInt(value)
    };
  }

  async function onSubmit(values: TransactionTemplateDialogData): Promise<void> {
    const props = transformValues(values);

    await handleRequest(async () => {
      let message: string;

      if (isNew) {
        const newTemplate = await transactionTemplateClient.create(props);
        if (onCreate) onCreate(newTemplate);
        message = 'Category Created';
      } else {
        const newTemplate = await transactionTemplateClient.update(template.duplicate(props));
        if (onUpdate) onUpdate(newTemplate);
        message = 'Category Updated';
      }

      snackbar.enqueueSnackbar(message, { variant: 'success' });
      closeDialog(onClose);
    }, snackbar);
  }

  async function handleDelete(): Promise<void> {
    await handleRequest(async () => {
      if (!isNew) {
        const newTemplate = await transactionTemplateClient.delete(template);
        if (onUpdate) onUpdate(newTemplate);
        snackbar.enqueueSnackbar('Category Disabled', { variant: 'success' });
        closeDialog(onClose);
      }
    }, snackbar);
  }

  async function restore(): Promise<void> {
    await handleRequest(async () => {
      if (!isNew) {
        const newTemplate = await transactionTemplateClient.restore(template);
        if (onUpdate) onUpdate(newTemplate);
        snackbar.enqueueSnackbar('Category Enabled', { variant: 'success' });
        closeDialog(onClose);
      }
    }, snackbar);
  }

  return (
    <Dialog {...dialogProps}>
      <DialogCloseTitle onClose={onClose}>{isNew ? 'Create' : 'Update'} Category</DialogCloseTitle>
      <Divider />
      <Form
        initialValues={isNew ? { requiresComment: false } : template.toDictionary()}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values, hasValidationErrors, submitting, initialValues }) => {
          const { description } = values;

          return (
            <>
              <DialogContent>
                <Show when={template != null || templateId == null}>
                  <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <TextField name="name" label="Name" fullWidth />
                      </Grid>
                      <Grid item>
                        <TextField
                          name="description"
                          label="Description"
                          fullWidth
                          multiline
                          helperText={description ? `${description?.length || 0}/${maxDescriptionLength}` : ''}
                        />
                      </Grid>
                      <Grid item>
                        <TextField type="number" name="value" label="Value" fullWidth />
                      </Grid>
                      <Grid item>
                        <Checkboxes
                          color="primary"
                          name="requiresComment"
                          data={{ label: 'Requires Comment', value: '' }}
                        />
                      </Grid>
                    </Grid>
                    <button className="d-none" type="submit" />
                  </form>
                </Show>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Show when={!isNew}>
                  {template?.kept ? (
                    <ErrorButton onClick={handleDelete}>Disable</ErrorButton>
                  ) : (
                    <Button onClick={restore}>Enable</Button>
                  )}
                </Show>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={hasValidationErrors || submitting || values === initialValues}
                >
                  Save
                </Button>
              </DialogActions>
            </>
          );
        }}
      />
    </Dialog>
  );
}
