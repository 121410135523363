import { CircularProgress, CircularProgressProps, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Hide } from 'components/visibility/Hide';
import { Show } from 'components/visibility/Show';
import React, { PropsWithChildren } from 'react';

export interface LoadableContainerProps<T> extends PropsWithChildren<any>, CircularProgressProps {
  data?: T | null;
  inline?: boolean;
  hideContent?: boolean;
  loading?: boolean;
  hasData?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    progress: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2)
    },
    inlineProgress: {
      display: 'inline-block',
      marginTop: theme.spacing(2)
    }
  })
);

export function LoadableContainer<T>(props: LoadableContainerProps<T>): React.ReactElement {
  const {
    data,
    inline = false,
    hideContent = inline,
    loading = data == null,
    hasData = !Array.isArray(data) || data.length !== 0,
    children,
    ...circularProgressProps
  } = props;
  const classes = useStyles();

  const progressFragment = (
    <>
      <Show when={loading}>
        <CircularProgress {...circularProgressProps} />
      </Show>
      <Hide when={loading || hasData}>
        <Typography component="div" variant="h5">
          No Results
        </Typography>
      </Hide>
    </>
  );

  return (
    <>
      <Hide when={hideContent && loading}>{children}</Hide>
      {inline ? progressFragment : <div className={classes.progress}>{progressFragment}</div>}
    </>
  );
}
