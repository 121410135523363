import { NumberIndexResourceClient } from 'clients/NumberIndexResourceClient';
import { NoParse, Parse, ResourceRequestParams } from 'clients/ResourceClient';
import { TransactionTemplate, TransactionTemplateProps } from 'models/TransactionTemplate';

export class TransactionTemplateClient extends NumberIndexResourceClient<
  TransactionTemplate,
  TransactionTemplateProps
> {
  constructor() {
    super(TransactionTemplate, 'transaction-templates');
  }

  restore(template: TransactionTemplate, params?: ResourceRequestParams & Parse): Promise<TransactionTemplate>;
  restore(template: TransactionTemplate, params: ResourceRequestParams & NoParse): Promise<TransactionTemplateProps>;
  restore(
    template: TransactionTemplate,
    params: ResourceRequestParams
  ): Promise<TransactionTemplate | TransactionTemplateProps>;
  restore(
    template: TransactionTemplate,
    params: ResourceRequestParams | undefined = template.requestParams
  ): Promise<TransactionTemplate | TransactionTemplateProps> {
    if (template.id == null) {
      throw new Error('Cannot update object without an ID');
    }

    return this.request({ ...params, method: 'POST', path: this.getPath(`${template.id}/restore`) });
  }
}

export const transactionTemplateClient = new TransactionTemplateClient();
