import { authenticationClient } from 'clients/AuthenticationClient';
import { config } from 'Config';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { handleUnauthorizedError } from 'RequestHelpers';

export function Logout(): React.ReactElement {
  const history = useHistory();
  const snackbar = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        await authenticationClient.logout();
        history.push('/login');
      } catch (e) {
        handleUnauthorizedError(e, history, snackbar);
      }
    })();

    document.title = `${config.appName} Logout`;
  }, []);

  return <></>;
}
