import { RequestError } from 'clients/RequestError';
import { config } from 'Config';

export interface RequestParams {
  query?: Dictionary;
  signal?: AbortSignal;
}
export interface RequestAllParams extends RequestParams {
  path: string;
  options?: RequestInit;
}
export interface ClientRequestParams extends RequestAllParams {
  method?: string;
  body?: any;
}

export abstract class Client {
  stringify(obj: any): string {
    return JSON.stringify(obj);
  }

  buildUrl(path: string, search?: Dictionary): string {
    if (!search) {
      return path;
    }

    const queryStrings = [];

    for (const key in search) {
      if (search.hasOwnProperty(key)) {
        const value = search[key];
        const queryValue = value instanceof Object ? this.stringify(value) : value;
        queryStrings.push(`${key}=${queryValue}`);
      }
    }

    return `${path}?${queryStrings.join('&')}`;
  }

  protected async _request(path: string, options: RequestInit): Promise<any> {
    const opts: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: config.authToken
      },
      ...options
    };

    const response = await fetch(`${config.apiBaseUrl}/${path}`, opts);
    if (!response.ok) {
      throw new RequestError(response, response.status, response.statusText);
    }

    const newAuthToken = response.headers.get('authorization');
    if (newAuthToken) {
      // If we get an Authorization header back then the token needs to be updated to match
      config.authToken = newAuthToken;
    }

    try {
      return await response.json();
    } catch (e) {
      if (e instanceof SyntaxError) {
        return null;
      }

      throw e;
    }
  }

  request(params: ClientRequestParams): Promise<any> {
    const { path, method = 'GET', body, options = {}, query, signal } = params;
    const searchPath = this.buildUrl(path, query);
    return this._request(searchPath, { method, body, signal, ...options });
  }

  requestAll(params: RequestAllParams): Promise<any[]> {
    const { path, options = {}, query, signal } = params;
    const searchPath = this.buildUrl(path, query);
    return this._request(searchPath, { method: 'GET', signal, ...options });
  }
}
