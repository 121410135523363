import { Button, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';
import { userClient } from 'clients/UserClient';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateConfirmPassword, validatePassword, validateRequired } from 'FormHelpers';
import { User } from 'models/User';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { handleRequestError } from 'RequestHelpers';

export interface UpdatePasswordData {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export interface ValidUpdatePasswordData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface UpdatePasswordDialogProps extends DialogProps {
  user: User;
  onUserChange: (user: User) => any;
}

export function UpdatePasswordDialog(props: UpdatePasswordDialogProps): React.ReactElement {
  const snackbar = useSnackbar();
  const { user, onUserChange, ...dialogProps } = props;
  const { onClose } = dialogProps;

  async function onSubmit(data: ValidUpdatePasswordData): Promise<void> {
    const { currentPassword, newPassword } = data;

    try {
      onUserChange(await userClient.updatePassword(user.id, currentPassword, newPassword, user.requestParams));
      snackbar.enqueueSnackbar('Password Updated', { variant: 'success' });
      if (onClose) onClose({}, 'backdropClick');
    } catch (e) {
      handleRequestError(e, snackbar, {
        401: { message: 'Incorrect Password' }
      });
    }
  }

  function validate(data: UpdatePasswordData): ValidationErrors {
    const { currentPassword, newPassword, confirmPassword } = data;

    return cleanErrors({
      currentPassword: validateRequired(currentPassword),
      newPassword: validatePassword(newPassword),
      confirmPassword: validateConfirmPassword(confirmPassword, newPassword)
    });
  }

  return (
    <Dialog {...dialogProps}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors, submitting }) => (
          <>
            <DialogCloseTitle onClose={onClose}>Update Password</DialogCloseTitle>
            <Divider />
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField name="currentPassword" type="password" label="Current Password" autoFocus />
                  </Grid>
                  <Grid item>
                    <TextField name="newPassword" type="password" label="New Password" />
                  </Grid>
                  <Grid item>
                    <TextField name="confirmPassword" type="password" label="Confirm Password" />
                  </Grid>
                </Grid>
                <button className="d-none" type="submit" />
              </form>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button onClick={handleSubmit} disabled={hasValidationErrors || submitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
}
