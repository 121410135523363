import { DateTime } from 'luxon';
import { Discardable, DiscardableProps, discardedFromDictionary, isKept } from 'models/Discardable';
import { Model } from 'models/Model';
import { NumberIndexModel, NumberIndexModelProps } from 'models/NumberIndexModel';
import { StringIndexModel } from 'models/StringIndexModel';
import { User, UserProps } from 'models/User';

export interface AuditProps extends NumberIndexModelProps, DiscardableProps {
  createdById?: string;
  date?: string | DateTime;
  score?: number;
  comment?: string;
  createdBy?: User | UserProps;
  users?: User[] | UserProps[];
}

export class Audit extends NumberIndexModel<AuditProps> implements Discardable {
  createdById = StringIndexModel.DEFAULT_ID;
  score = 0;
  comment = '';

  private _discardedAt: DateTime = Model.DEFAULT_DATE_TIME;
  get discardedAt(): DateTime {
    return this._discardedAt;
  }
  set discardedAt(value: DateTime) {
    this._discardedAt = value;
  }

  private _date = Model.DEFAULT_DATE_TIME;
  get date(): DateTime {
    return this._date;
  }
  set date(value: DateTime) {
    this._date = value;
  }

  private _users!: User[];
  get users(): User[] {
    return this._users;
  }
  set users(value: User[]) {
    this._users = value;
  }

  private _createdBy!: User;
  get createdBy(): User {
    return this._createdBy;
  }
  set createdBy(value: User) {
    this._createdBy = value;
  }

  get kept(): boolean {
    return isKept(this);
  }

  get discarded(): boolean {
    return !this.kept;
  }

  constructor(props: AuditProps = {}) {
    super();
    this.init(props);
  }

  fromDictionary(props: AuditProps): void {
    super.fromDictionary(props);
    discardedFromDictionary(this, props);

    if (props.date) {
      if (typeof props.date === 'string') {
        this.date = DateTime.fromISO(props.date, { zone: 'utc' });
      } else {
        this.date = props.date;
      }
    }

    if (Array.isArray(props.users) && props.users[0] instanceof User) {
      this.users = props.users as User[];
    } else {
      this.users = ((props.users || []) as UserProps[]).map((props: UserProps) => new User(props));
    }

    if (props.createdBy instanceof User) {
      this.createdBy = props.createdBy;
    } else {
      this.createdBy = new User(props.createdBy || {});
    }
  }

  toDictionary(): AuditProps {
    const props = super.toDictionary();

    props.date = this.date.toISODate() as string;

    return props;
  }
}
