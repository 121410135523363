import { Typography } from '@material-ui/core';
import { config } from 'Config';
import React, { useEffect } from 'react';

export function NotFound(): React.ReactElement {
  useEffect(() => {
    document.title = `${config.appName} Not Found`;
  });

  return (
    <>
      <Typography component="h1" variant="h3">
        Page Not Found
      </Typography>
    </>
  );
}
