import { config } from 'Config';
import { DateTime } from 'luxon';
import { Audit, AuditProps } from 'models/Audit';
import { Discardable, isKept } from 'models/Discardable';
import { Model } from 'models/Model';
import { Role, RoleProps } from 'models/Role';
import { StringIndexModel, StringIndexModelProps } from 'models/StringIndexModel';
import { Transaction, TransactionProps } from 'models/Transaction';

export interface UserProps extends StringIndexModelProps {
  email?: string;
  emailVerified?: boolean;
  name?: string;
  displayName?: string;
  currentTransactionId?: number | null;
  currentTransaction?: Transaction | TransactionProps | null;
  latestAudit?: Audit | AuditProps | null;
  roleId?: string;
  role?: Role | RoleProps;
  discardedAt?: string | null;
  avatarUrl?: string | null;
}

export class User extends StringIndexModel<UserProps> implements Discardable {
  email = '';
  emailVerified = false;
  name = '';
  displayName = '';
  currentTransactionId: number | null = null;
  roleId = '';
  avatarUrl: string | null = null;

  private _discardedAt: DateTime = Model.DEFAULT_DATE_TIME;
  get discardedAt(): DateTime {
    return this._discardedAt;
  }
  set discardedAt(value: DateTime) {
    this._discardedAt = value;
  }

  get avatar(): string | undefined {
    return this.avatarUrl ? `${config.apiBaseUrl}${this.avatarUrl}` : undefined;
  }

  get kept(): boolean {
    return isKept(this);
  }
  get discarded(): boolean {
    return !this.kept;
  }

  private _currentTransaction: Transaction | null = null;
  get currentTransaction(): Transaction | null {
    return this._currentTransaction;
  }
  set currentTransaction(value: Transaction | null) {
    this._currentTransaction = value;
  }

  private _latestAudit: Audit | null = null;
  get latestAudit(): Audit | null {
    return this._latestAudit;
  }
  set latestAudit(value: Audit | null) {
    this._latestAudit = value;
  }

  private _role = new Role();
  get role(): Role {
    return this._role;
  }
  set role(value: Role) {
    this._role = value;
  }

  get balance(): number {
    return this.currentTransaction?.balance || 0;
  }

  constructor(data: UserProps = {}) {
    super();
    this.init(data);
  }

  fromDictionary(props: UserProps): void {
    super.fromDictionary(props);

    this.discardedAt = DateTime.fromISO(props.discardedAt || Model.DEFAULT_DATE_TIME_STRING);

    if (props.currentTransaction == null) {
      this.currentTransaction = null;
    } else if (props.currentTransaction instanceof Transaction) {
      this.currentTransaction = props.currentTransaction;
    } else {
      const transactionProps: TransactionProps = props.currentTransaction || {};
      transactionProps.user = this;
      transactionProps.createdBy = this;
      this.currentTransaction = new Transaction(transactionProps);
    }

    if (props.latestAudit == null) {
      this.latestAudit = null;
    } else if (props.latestAudit instanceof Audit) {
      this.latestAudit = props.latestAudit;
    } else {
      this.latestAudit = new Audit(props.latestAudit);
    }

    if (props.role instanceof Role) {
      this.role = props.role;
    } else {
      this.role = new Role(props.role || {});
    }
  }

  toDictionary(): UserProps {
    const props = super.toDictionary();

    props.discardedAt = this.discardedAt.toISO();
    props.currentTransaction = this.currentTransaction?.toDictionary();
    props.role = this.role?.toDictionary();

    return props;
  }

  hasAccessLevel(role: string): boolean {
    return this.role.hasAccessLevel(role);
  }
}
