import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';

export type HeadingProps<D extends React.ElementType = 'h1'> = TypographyProps<D> & { level: number };

export function Heading<D extends React.ElementType = 'h1'>(props: HeadingProps<D>): React.ReactElement {
  const { level, ...typographyProps } = props;
  const variant = `h${level + 1}`;
  const component = `h${level}`;

  return <Typography component={component as 'h1'} variant={variant as 'h1'} {...typographyProps} />;
}
