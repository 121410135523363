import { Typography } from '@material-ui/core';
import { userClient } from 'clients/UserClient';
import { config } from 'Config';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { handleRequestError } from 'RequestHelpers';
import { useQuery } from 'Utils';

interface ResetPasswordData {
  password?: string;
  confirmPassword?: string;
}
interface ValidResetPasswordData {
  password: string;
  confirmPassword: string;
}

interface VerifyEmailProps {
  user: User;
  onChange: (user: User) => any;
}

export function VerifyEmail(props: VerifyEmailProps): React.ReactElement {
  const { user, onChange } = props;
  const history = useHistory();
  const query = useQuery();
  const snackbar = useSnackbar();
  const { emailVerificationTokenId } = useParams();
  const [mountComplete, setMountComplete] = useState(false);
  const token = query.get('token');

  useEffect(() => {
    (async () => {
      try {
        onChange(
          await userClient.verifyEmail(user.id, emailVerificationTokenId, query.get('token') || '', user.requestParams)
        );
        snackbar.enqueueSnackbar('Email Verified', { variant: 'success' });
        history.push('/account');
      } catch (e) {
        handleRequestError(e, snackbar, {
          404: { skipSnackbar: true }
        });

        setMountComplete(true);
      }
    })();

    document.title = `${config.appName} Verify Email`;
  }, []);

  if (!mountComplete) return <></>;

  return (
    <>
      <Typography component="h1" variant="h4">
        Invalid Email Verification Request
      </Typography>
      <Typography>
        Your request to verify your email was not found because it does not exist or has expired.{' '}
        <Link to="/account">Update your email address</Link> to send a new verification request.
      </Typography>
    </>
  );
}
