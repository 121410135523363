import { ThemeProvider } from '@material-ui/core';
import { AcceptInvitation } from 'components/views/AcceptInvitation';
import { ContentWrapper } from 'components/views/ContentWrapper';
import { ForgotPassword } from 'components/views/ForgotPassword';
import { Login } from 'components/views/Login';
import { Logout } from 'components/views/Logout';
import { ResetPassword } from 'components/views/ResetPassword';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { themes } from 'Themes';

export function App(): React.ReactElement {
  return (
    <ThemeProvider theme={themes.primary}>
      <Router>
        <Switch>
          {[
            { path: '/login', component: <Login /> },
            { path: '/logout', component: <Logout /> },
            { path: '/forgot-password', component: <ForgotPassword /> },
            { path: '/reset-password/:id', component: <ResetPassword /> },
            { path: '/accept-invitation/:id', component: <AcceptInvitation /> }
          ].map((obj) => (
            <Route key={obj.path} path={obj.path}>
              {obj.component}
            </Route>
          ))}
          <Route>
            <ContentWrapper />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
