import { createStyles, makeStyles, Table as MaterialTable, TableProps as MaterialTableProps } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { classList } from 'Utils';

const useStyles = makeStyles((theme) => {
  const stickyOffset: Dictionary<CSSProperties> = {};
  const toolbar: Dictionary<CSSProperties> = theme.mixins.toolbar as Dictionary<CSSProperties>;

  for (const prop in toolbar) {
    if (toolbar.hasOwnProperty(prop)) {
      stickyOffset[prop] = {};
      stickyOffset[prop].top = toolbar[prop].minHeight;
    }
  }

  return createStyles({
    lessHeaderPadding: {
      '& th': {
        padding: theme.spacing(1)
      }
    },
    lessBodyPadding: {
      '& td': {
        padding: theme.spacing(1)
      }
    },
    stickyOffset: {
      '& th': stickyOffset
    }
  });
});

export interface TableProps extends MaterialTableProps {
  stickyToolbar?: boolean;
  lessPadding?: boolean; // Useful when rows contain elements with padding like buttons
  lessHeaderPadding?: boolean;
  lessBodyPadding?: boolean;
}

export function Table(props: TableProps): React.ReactElement {
  const {
    stickyToolbar,
    lessPadding,
    lessHeaderPadding = lessPadding,
    lessBodyPadding = lessPadding,
    ...tableProps
  } = props;
  const classes = useStyles();
  const tableClasses = classList([
    lessHeaderPadding && classes.lessHeaderPadding,
    lessBodyPadding && classes.lessBodyPadding,
    stickyToolbar && classes.stickyOffset
  ]);

  if (stickyToolbar) {
    tableProps.stickyHeader = true;
  }

  return (
    <>
      <MaterialTable className={tableClasses} {...tableProps} />
    </>
  );
}
