import { ValidationErrors } from 'final-form';

export function cleanErrors(errors: ValidationErrors): ValidationErrors {
  // remove keys with falsey values
  for (const field in errors) {
    if (errors.hasOwnProperty(field) && !errors[field]) {
      delete errors[field];
    }
  }

  return errors;
}

export function validateRequired(value?: any): string | null {
  if (value) {
    return null;
  } else {
    return 'Required';
  }
}

export function validateInt(value: string | null | undefined): string | null {
  if (/^[-+]?[0-9]*$/.test(value || '')) {
    return null;
  } else {
    return 'Must be a whole number';
  }
}

export function validateMin(value: string | null | undefined, minValue: number): string | null {
  if (minValue <= Number.parseFloat(value || 'Infinity')) {
    return null;
  } else {
    return `Must less than ${minValue}`;
  }
}

export function validateMax(value: string | null | undefined, maxValue: number): string | null {
  if (Number.parseFloat(value || '-Infinity') <= maxValue) {
    return null;
  } else {
    return `Must less than ${maxValue}`;
  }
}

export function validateRange(value: string | null | undefined, minValue: number, maxValue: number): string | null {
  if (minValue <= Number.parseFloat(value || 'Infinity') && Number.parseFloat(value || '-Infinity') <= maxValue) {
    return null;
  } else {
    return `Must be between ${minValue} and ${maxValue}`;
  }
}

export function validateMinLength(value: string | null | undefined, min: number): string | null {
  if (!value || value.length < min) {
    return `Must be at least ${min} characters`;
  } else {
    return null;
  }
}

export function validateMaxLength(value: string | null | undefined, max: number): string | null {
  if (value && value.length > max) {
    return `${value?.length || 0}/${max}`;
  } else {
    return null;
  }
}

export function validateEmail(email?: string | null): string | null {
  if (email && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) != null) {
    return null;
  } else {
    return 'Invalid Email Address';
  }
}

export function validatePassword(password?: string | null): string | null {
  return validateMinLength(password, 8);
}

export function validateConfirmPassword(confirmPassword?: string | null, password?: string | null): string | null {
  if (confirmPassword === password) {
    return null;
  } else {
    return 'Passwords do not match';
  }
}
