import { Button, Grid, IconButton } from '@material-ui/core';
import { ArrowBack, Clear, Edit } from '@material-ui/icons';
import { auditClient } from 'clients/AuditClient';
import { AvatarName } from 'components/AvatarName';
import { AddAuditUserDialog } from 'components/dialogs/AddAuditUserDialog';
import { Fab } from 'components/Fab';
import { Heading } from 'components/Heading';
import { LoadableContainer } from 'components/LoadableContainer';
import { AddEditAuditView } from 'components/views/Audits/AddEditAuditView';
import { DateTime } from 'luxon';
import { Audit } from 'models/Audit';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { abortEffect, handleRequest } from 'RequestHelpers';
import { getParentPath } from 'Utils';

export interface AuditViewProps {
  currentUser: User;
}

export function AuditView(props: AuditViewProps): React.ReactElement {
  const { currentUser } = props;
  const { id } = useParams();
  const { url } = useRouteMatch();
  const snackbar = useSnackbar();
  const [audit, setAudit] = useState<Audit | undefined>();
  const [users, setUsers] = useState<User[]>([]);
  const [editing, setEditing] = useState(false);
  const [addingUsers, setAddingUsers] = useState(false);
  const parentPath = getParentPath(url);

  useEffect(() => {
    return abortEffect(async (signal) => {
      await handleRequest(async () => {
        setAudit(await auditClient.get(id, { query: { include: 'createdBy,users' }, signal }));
      }, snackbar);
    });
  }, [id]);

  useEffect(() => {
    if (audit == null) {
      return;
    }

    return abortEffect(async (signal) => {
      await handleRequest(async () => {
        setUsers(await auditClient.getUsers(id, { signal }));
      }, snackbar);
    });
  }, [audit]);

  async function reload(): Promise<void> {
    if (audit) {
      setAudit(await auditClient.reload(audit));
    }
  }

  async function removeUser(user: User): Promise<void> {
    await handleRequest(
      async () => {
        if (audit) {
          await auditClient.removeUser(audit.id, user.id);
          // snackbar.enqueueSnackbar('User removed', { variant: 'success' });
          await reload();
        } else {
          // raise an error if the audit isn't provided
          throw new Error('Audit not provided');
        }
      },
      snackbar,
      {},
      { message: 'Error removing user from audit' }
    );
  }

  const viewComponent = (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" spacing={1} wrap="nowrap">
            <Grid item>
              <IconButton component={Link} to={parentPath} style={{ marginTop: '2px' }}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item>
              <Heading level={1} variant="h3">
                Audit
              </Heading>
            </Grid>
          </Grid>
        </Grid>
        <LoadableContainer data={audit} hideContent>
          <Grid item>
            <Heading level={2} variant="h5">
              Submitted by <b>{audit?.createdBy.name}</b> at{' '}
              <b>{audit?.createdAt.toLocaleString(DateTime.DATETIME_SHORT)}</b>
            </Heading>
          </Grid>
          <Grid item>
            <Heading level={2} variant="h6">
              Date: <b>{audit?.date.toLocaleString(DateTime.DATE_SHORT)}</b>
            </Heading>
          </Grid>
          <Grid item>
            <Heading level={2} variant="h6">
              Score: <b>{audit?.score}</b>
            </Heading>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Heading level={2} variant="h6">
                  Comment
                </Heading>
              </Grid>
              <Grid item className="pre-wrap">
                {audit?.comment}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Heading level={2} variant="h6">
                  Users
                </Heading>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  {users.length ? (
                    users.map((user) => (
                      <Grid item key={user.id}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <AvatarName user={user} />
                          </Grid>
                          <Grid item>
                            <IconButton onClick={() => removeUser(user)}>
                              <Clear />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Grid item>None</Grid>
                  )}
                  <Grid item>
                    <Button color="primary" variant="contained" onClick={() => setAddingUsers(true)}>
                      Add More Users
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LoadableContainer>
        <Fab onClick={() => setEditing(true)}>
          <Edit />
        </Fab>
      </Grid>
      <AddAuditUserDialog
        currentUser={currentUser}
        auditId={audit?.id}
        open={addingUsers}
        onClose={async () => {
          setAddingUsers(false);
          await reload();
        }}
      />
    </>
  );

  return editing ? (
    <AddEditAuditView
      audit={audit}
      onClose={async () => {
        setEditing(false);
        await reload();
      }}
    />
  ) : (
    viewComponent
  );
}
