import { Button, Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { auditClient } from 'clients/AuditClient';
import { Heading } from 'components/Heading';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateInt, validateMaxLength, validateRange, validateRequired } from 'FormHelpers';
import { DateTime } from 'luxon';
import { Audit } from 'models/Audit';
import { Model } from 'models/Model';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import { handleRequest, handleRequestError } from 'RequestHelpers';
import { getParentPath } from 'Utils';

export interface AddEditAuditViewProps {
  audit?: Audit;
  onClose: () => any;
}

interface FormValues {
  date?: string;
  score?: string;
  comment?: string;
}

interface ValidFormValues {
  date: string;
  score: number;
  comment?: string;
}

export function AddEditAuditView(props: AddEditAuditViewProps): React.ReactElement {
  const { audit = new Audit(), onClose } = props;
  const history = useHistory();
  const location = useLocation();
  const snackbar = useSnackbar();
  const commentMaxLength = 250;

  function transformValues(values: FormValues): ValidFormValues {
    const { date, score, comment } = values;

    return {
      date: date || Model.DEFAULT_DATE_TIME_STRING,
      score: Number.parseInt(score || '0'),
      comment: comment
    };
  }

  async function onSubmit(values: FormValues): Promise<void> {
    const data = transformValues(values);

    await handleRequest(
      async () => {
        let message: string;

        if (audit.isNew) {
          const audit = await auditClient.create(data);
          message = 'Audit created';

          history.push(`${getParentPath(location.pathname)}/${audit.id}`);
        } else {
          audit.fromDictionary(data);
          await auditClient.update(audit);
          message = 'Audit updated';

          onClose();
        }

        snackbar.enqueueSnackbar(message, { variant: 'success' });
      },
      snackbar,
      {},
      { message: 'Error saving audit' }
    );
    try {
    } catch (e) {
      handleRequestError(e, snackbar, {}, { message: 'Error saving audit' });
    }
  }

  async function validate(values: FormValues): Promise<ValidationErrors> {
    const { date, score, comment } = values;

    return cleanErrors({
      date: validateRequired(date),
      score: validateRequired(score) || validateInt(score) || validateRange(score, 0, 100),
      comment: validateMaxLength(comment, commentMaxLength)
    });
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item>
            <Heading level={1}>{audit.isNew ? 'New' : 'Edit'} Audit</Heading>
          </Grid>
        </Grid>
      </Grid>

      <Form
        initialValues={audit.isNew ? { date: DateTime.local().toISODate() } : audit.toDictionary()}
        onSubmit={onSubmit}
        validate={validate}
        component={({ hasValidationErrors, submitting, handleSubmit, values }) => {
          const { comment } = values;

          return (
            <>
              <Grid item>
                <TextField name="date" label="Date" type="date" />
              </Grid>
              <Grid item>
                <TextField name="score" label="Score" type="number" required />
              </Grid>
              <Grid item>
                <TextField
                  name="comment"
                  label="Comment"
                  variant="outlined"
                  helperText={comment ? `${comment?.length || 0}/${commentMaxLength}` : ''}
                  multiline
                  rowsMax={Infinity}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={hasValidationErrors || submitting}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </>
          );
        }}
      />
    </Grid>
  );
}
