import { Grid } from '@material-ui/core';
import { Avatar, AvatarProps } from 'components/Avatar';
import { LeaderboardUser } from 'models/LeaderboardUser';
import { User } from 'models/User';
import React from 'react';

export interface AvatarNameProps extends AvatarProps {
  user: User | LeaderboardUser;
  useDisplayName?: boolean;
}

export function AvatarName(props: AvatarNameProps): React.ReactElement {
  const { user, size, useDisplayName = false, ...avatarProps } = props;

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Avatar src={user.avatar} size={size} {...avatarProps} />
      </Grid>
      <Grid item>{useDisplayName ? user.displayName : user.name}</Grid>
    </Grid>
  );
}
