import { createMuiTheme, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

class ApplicationTheme {
  private _primary = createMuiTheme({ palette: { primary: { main: '#19581f' } } });
  get primary(): Theme {
    return this._primary;
  }

  private _error = createMuiTheme({ palette: { primary: red } });
  get error(): Theme {
    return this._error;
  }
}

export const themes = new ApplicationTheme();
