import { NumberIndexResourceClient } from 'clients/NumberIndexResourceClient';
import { userClient } from 'clients/UserClient';
import { Audit, AuditProps } from 'models/Audit';
import { User, UserProps } from 'models/User';

import { NoParse, Parse, ResourceRequestParams } from './ResourceClient';

export class AuditClient extends NumberIndexResourceClient<Audit, AuditProps> {
  constructor() {
    super(Audit, 'audits');
  }

  getUsers(auditId: number, params?: ResourceRequestParams & Parse): Promise<User[]>;
  getUsers(auditId: number, params: ResourceRequestParams & NoParse): Promise<UserProps[]>;
  getUsers(auditId: number, params: ResourceRequestParams): Promise<User[] | UserProps[]>;
  getUsers(auditId: number, params?: ResourceRequestParams): Promise<User[] | UserProps[]> {
    return userClient.requestAll({
      ...params,
      path: this.getPath(`${auditId}/${userClient.getPath()}`)
    });
  }

  addUser(auditId: number, userId: string, params?: ResourceRequestParams & Parse): Promise<User>;
  addUser(auditId: number, userId: string, params: ResourceRequestParams & NoParse): Promise<UserProps>;
  addUser(auditId: number, userId: string, params: ResourceRequestParams): Promise<User | UserProps>;
  addUser(auditId: number, userId: string, params?: ResourceRequestParams): Promise<User | UserProps> {
    return userClient.request({
      ...params,
      method: 'POST',
      path: this.getPath(`${auditId}/${userClient.getPath(userId)}`)
    });
  }

  removeUser(auditId: number, userId: string, params?: ResourceRequestParams & Parse): Promise<User>;
  removeUser(auditId: number, userId: string, params: ResourceRequestParams & NoParse): Promise<UserProps>;
  removeUser(auditId: number, userId: string, params: ResourceRequestParams): Promise<User | UserProps>;
  removeUser(auditId: number, userId: string, params?: ResourceRequestParams): Promise<User | UserProps> {
    return userClient.request({
      ...params,
      method: 'DELETE',
      path: this.getPath(`${auditId}/${userClient.getPath(userId)}`)
    });
  }
}

export const auditClient = new AuditClient();
