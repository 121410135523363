import { Dialog as MaterialDialog, DialogProps as MaterialDialogProps } from '@material-ui/core';
import React from 'react';

export interface DialogProps extends MaterialDialogProps {
  onClose: DialogCloseFunction;
}

export function Dialog(props: DialogProps): React.ReactElement {
  return <MaterialDialog maxWidth="xs" fullWidth={true} {...props} />;
}
