import md5 from 'md5';
import { Model, ModelProps } from 'models/Model';
import { useReducer } from 'react';
import { useLocation } from 'react-router-dom';

export function findById<T extends ID>(id: T): (obj: Model<T, ModelProps<T>>) => boolean {
  return (obj: Model<T, ModelProps<T>>) => obj.id === id;
}

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export function closeDialog(
  onClose: DialogCloseFunction,
  reason: 'backdropClick' | 'escapeKeyDown' = 'backdropClick'
): void {
  onClose({}, reason);
}

export function classList(classes: Array<string | false | null | undefined>): string {
  return classes.filter((clazz) => clazz).join(' ');
}

export function getParentPath(path: string): string {
  return path.substring(0, path.lastIndexOf('/'));
}

export interface GravatarImageOptions {
  defaultImage?: string;
  size?: number;
}

export function gravatarImage(email: string, options: GravatarImageOptions = {}): string {
  const { defaultImage = 'identicon', size = 40 } = options;

  return `https://www.gravatar.com/avatar/${md5(email)}?d=${defaultImage}&size=${size * 3}`;
}

// Create a trigger can be set as a dependency and a function that fires the trigger when called
export function useTrigger(): [number, () => void] {
  return useReducer((state) => state + 1, 0);
}
