import { Parse, ResourceRequestParams } from 'clients/ResourceClient';
import { DateTime } from 'luxon';

export interface ModelConstructor<T extends Model<I, P>, P extends ModelProps<I>, I extends ID> {
  new (data: P): T;
}

export interface ModelProps<T extends ID> {
  id?: T;
  createdAt?: string;
  updatedAt?: string;
  requestParams?: ResourceRequestParams & Parse;
}

export abstract class Model<T extends ID, P extends ModelProps<T>> {
  static DEFAULT_DATE_TIME_STRING = '2000-01-01';
  static DEFAULT_DATE_TIME = DateTime.fromISO(Model.DEFAULT_DATE_TIME_STRING);

  abstract id: T;
  requestParams?: ResourceRequestParams & Parse;

  private _createdAt: DateTime = Model.DEFAULT_DATE_TIME;
  get createdAt(): DateTime {
    return this._createdAt;
  }
  set createdAt(value: DateTime) {
    this._createdAt = value;
  }
  private _updatedAt: DateTime = Model.DEFAULT_DATE_TIME;
  get updatedAt(): DateTime {
    return this._updatedAt;
  }
  set updatedAt(value: DateTime) {
    this._updatedAt = value;
  }

  // Sub-classes should use this constructor
  // constructor(data: Dictionary = {}) {
  //   super();
  //   this.init(data);
  // }

  init(data: P): void {
    this.fromDictionary(data);
  }

  abstract get isNew(): boolean;

  fromDictionary(props: P): void {
    const data: Dictionary = props;

    for (const prop in data) {
      if (data.hasOwnProperty(prop) && this.hasOwnProperty(prop) && data[prop] !== undefined) {
        (this as Dictionary)[prop] = data[prop];
      }
    }

    this.createdAt = DateTime.fromISO(data['createdAt'] || Model.DEFAULT_DATE_TIME_STRING);
    this.updatedAt = DateTime.fromISO(data['updatedAt'] || Model.DEFAULT_DATE_TIME_STRING);
  }

  toDictionary(): P {
    const data: Dictionary = {};

    for (const prop in this) {
      if (this.hasOwnProperty(prop) && prop[0] !== '_') {
        data[prop] = (this as Dictionary)[prop];
      }
    }

    data['createdAt'] = this.createdAt.toISO();
    data['updatedAt'] = this.updatedAt.toISO();

    return data as P;
  }

  duplicate(props?: P): this {
    return new (this.constructor as ModelConstructor<this, P, T>)({ ...this.toDictionary(), ...props });
  }
}
