import { Button, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';
import { userClient } from 'clients/UserClient';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateEmail, validateRequired } from 'FormHelpers';
import { User } from 'models/User';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Form } from 'react-final-form';
import { handleRequestError } from 'RequestHelpers';

export interface UpdateEmailData {
  currentPassword?: string;
  newEmail?: string;
}

export interface ValidUpdateEmailData {
  currentPassword: string;
  newEmail: string;
}

export interface UpdateEmailDialogProps extends DialogProps {
  user: User;
  onUserChange?: (user: User) => any;
}

export function UpdateEmailDialog(props: UpdateEmailDialogProps): React.ReactElement {
  const snackbar = useSnackbar();
  const { user, onUserChange, ...dialogProps } = props;
  const { onClose } = dialogProps;

  async function onSubmit(data: ValidUpdateEmailData): Promise<void> {
    const { currentPassword, newEmail } = data;

    try {
      const newUser = await userClient.updateEmail(user.id, currentPassword, newEmail, user.requestParams);
      snackbar.enqueueSnackbar(newUser.emailVerified ? 'Verification Email Sent' : 'Email Address Updated', {
        variant: 'success'
      });
      if (onUserChange) onUserChange(newUser);
      if (onClose) onClose({}, 'backdropClick');
    } catch (e) {
      handleRequestError(e, snackbar, {
        401: { message: 'Incorrect Password' }
      });
    }
  }

  function validate(data: UpdateEmailData): ValidationErrors {
    const { currentPassword, newEmail } = data;

    return cleanErrors({
      currentPassword: validateRequired(currentPassword),
      newEmail: validateEmail(newEmail)
    });
  }

  return (
    <Dialog {...dialogProps}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors, submitting }) => (
          <>
            <DialogCloseTitle onClose={onClose}>Update Email Address</DialogCloseTitle>
            <Divider />
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField name="newEmail" type="email" label="New Email" autoFocus />
                  </Grid>
                  <Grid item>
                    <TextField name="currentPassword" type="password" label="Your Password" />
                  </Grid>
                </Grid>
                <button className="d-none" type="submit" />
              </form>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button onClick={handleSubmit} disabled={hasValidationErrors || submitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
}
