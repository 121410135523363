import { config } from 'Config';
import { StringIndexModel, StringIndexModelProps } from 'models/StringIndexModel';

export interface LeaderboardUserProps extends StringIndexModelProps {
  name?: string | null;
  displayName?: string | null;
  value?: number | null;
  avatarUrl?: string | null;
}

export class LeaderboardUser extends StringIndexModel<LeaderboardUserProps> {
  name = '';
  displayName = '';
  value = 0;
  avatarUrl: string | null = null;

  get avatar(): string | undefined {
    return this.avatarUrl ? `${config.apiBaseUrl}${this.avatarUrl}` : undefined;
  }

  constructor(data: LeaderboardUserProps = {}) {
    super();
    this.init(data);
  }
}
