import { DateTime } from 'luxon';
import { Model } from 'models/Model';

export interface DiscardableProps {
  discardedAt?: string;
}

export interface Discardable {
  discardedAt: DateTime;
  readonly kept: boolean;
  readonly discarded: boolean;
}

export function isKept(obj: Discardable): boolean {
  return obj.discardedAt.equals(Model.DEFAULT_DATE_TIME);
}

export function discardedFromDictionary(obj: Discardable, props: DiscardableProps): void {
  if (props.discardedAt) {
    obj.discardedAt = DateTime.fromISO(props.discardedAt);
  }
}
