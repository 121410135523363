import { Container, ContainerProps, Grid } from '@material-ui/core';
import React from 'react';

export function CenteredContainer(props: ContainerProps): React.ReactElement {
  return (
    <Grid container className="vh-100" justify="center" alignItems="center">
      <Grid item xs>
        <Container maxWidth="xs" {...props}>
          {props.children}
        </Container>
      </Grid>
    </Grid>
  );
}
