import { Grid, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { auditClient } from 'clients/AuditClient';
import { quarterDateQuery, QuarterSelector } from 'components/QuarterSelector';
import { Table } from 'components/Table';
import { TableView } from 'components/TableView';
import { DateTime } from 'luxon';
import { Audit } from 'models/Audit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { abortEffect, handleRequest } from 'RequestHelpers';

export function AuditsView(): React.ReactElement {
  const { url } = useRouteMatch();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [audits, setAudits] = useState<Audit[]>([]);
  const [loading, setLoading] = useState(true);
  const [quarter, setQuarter] = useState(0);
  // const [selectedAuditId, setSelectedAuditId] = useState<number | null>(null);
  // const [auditDialogOpen, setAuditDialogOpen] = useState(false);
  // const [showDiscarded, setShowDiscarded] = useState(false);

  const keptAudits = audits.filter((audit) => audit.kept);
  // const discardedAudits = audits.filter((audit) => audit.discarded);

  useEffect(() => {
    const query = { ...quarterDateQuery(quarter), include: 'createdBy' };
    setAudits([]);
    setLoading(true);

    return abortEffect(async (signal) => {
      await handleRequest(async () => {
        setAudits(await auditClient.getAll({ query, signal }));
      }, snackbar).then();

      if (!signal.aborted) setLoading(false);
    });
  }, [quarter]);

  // function onDialogClose(): void {
  //   setAuditDialogOpen(false);
  //   setSelectedAuditId(null);
  // }
  //
  // function onCreate(audit: Audit): void {
  //   const newAudits = audits.slice();
  //   newAudits.unshift(audit);
  //   setAudits(newAudits);
  // }
  //
  // function onUpdate(audit: Audit): void {
  //   const newAudits = audits.slice();
  //   newAudits.splice(newAudits.findIndex(findById(audit.id)), 1, audit);
  //   setAudits(newAudits);
  // }

  return (
    <TableView title="Audits" data={audits} loading={loading} onFabClick={() => history.push(`${url}/new`)}>
      {/*<FormControlLabel*/}
      {/*  label="Show Disabled"*/}
      {/*  control={*/}
      {/*    <Checkbox color="primary" checked={showDiscarded} onChange={(e) => setShowDiscarded(e.target.checked)} />*/}
      {/*  }*/}
      {/*/>*/}
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <QuarterSelector quarter={quarter} onQuarterChange={setQuarter} />
        </Grid>
        <Grid item>
          <Table stickyToolbar>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Score</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>
            <TableBody>
              {keptAudits.map((audit) => (
                <TableRow key={audit.id} hover onClick={() => history.push(`${url}/${audit.id}`)}>
                  <TableCell>{audit.date.toLocaleString(DateTime.DATE_FULL)}</TableCell>
                  <TableCell>{audit.createdBy.name}</TableCell>
                  <TableCell>{audit.score}</TableCell>
                  <TableCell className="pre-wrap">{audit.comment}</TableCell>
                  <TableCell padding="checkbox">
                    <ChevronRight />
                  </TableCell>
                </TableRow>
              ))}
              {/*<Show when={showDiscarded}>*/}
              {/*  <TableRow>*/}
              {/*    <TableCell colSpan={5}>*/}
              {/*      <b>Disabled Categories</b>*/}
              {/*    </TableCell>*/}
              {/*  </TableRow>*/}
              {/*  {discardedAudits.map((audit) => (*/}
              {/*    <TableRow key={audit.id} hover onClick={() => setSelectedAuditId(audit.id)}>*/}
              {/*      <TableCell>{audit.name}</TableCell>*/}
              {/*      <TableCell>{audit.description}</TableCell>*/}
              {/*      <TableCell>{audit.value}</TableCell>*/}
              {/*      <TableCell>{audit.requiresComment ? 'Yes' : 'No'}</TableCell>*/}
              {/*      <TableCell padding="checkbox">*/}
              {/*        <ChevronRight />*/}
              {/*      </TableCell>*/}
              {/*    </TableRow>*/}
              {/*  ))}*/}
              {/*</Show>*/}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      {/*<AuditDialog*/}
      {/*  onClose={onDialogClose}*/}
      {/*  open={auditDialogOpen || selectedAuditId != null}*/}
      {/*  auditId={selectedAuditId}*/}
      {/*  onCreate={onCreate}*/}
      {/*  // onUpdate={onUpdate}*/}
      {/*/>*/}
    </TableView>
  );
}
