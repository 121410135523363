import { createStyles, Fab as MaterialFab, FabProps as MaterialFabProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => {
  const fabOffset = theme.spacing(3);

  return createStyles({
    fab: {
      position: 'fixed',
      bottom: fabOffset,
      right: fabOffset
    },
    fabMargin: {
      height: 56 + fabOffset
    }
  });
});

export type FabProps = MaterialFabProps;

export function Fab(props: FabProps): React.ReactElement {
  const { color = 'primary', ...fabProps } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.fabMargin} />
      <MaterialFab className={classes.fab} color={color} {...fabProps} />
    </>
  );
}
