import { Client, ClientRequestParams, RequestAllParams, RequestParams } from 'clients/Client';
import { Model, ModelConstructor, ModelProps } from 'models/Model';

export interface ResourceRequestParams extends RequestParams {
  parse?: boolean;
}
export type RequestAllResourcesParams = ResourceRequestParams & RequestAllParams;
export type RequestResourceParams = RequestAllResourcesParams & ClientRequestParams;

export interface Parse {
  parse?: true;
}
export interface NoParse {
  parse: false;
}

export abstract class ResourceClient<T extends Model<I, P>, P extends ModelProps<I>, I extends ID> extends Client {
  getPath(path?: ID | string): string {
    return this.basePath + (path ? `/${path}` : '');
  }

  protected constructor(public model: ModelConstructor<T, P, I>, public basePath: string) {
    super();
  }

  request(params: RequestResourceParams & Parse): Promise<T>;
  request(params: RequestResourceParams & NoParse): Promise<P>;
  request(params: RequestResourceParams): Promise<T | P>;
  async request(params: RequestResourceParams): Promise<T | P> {
    const { parse = true, query } = params;
    const data: P = await super.request(params);
    data.requestParams = { query };

    if (parse) {
      return new this.model(data);
    }

    return data;
  }

  requestAll(params: RequestAllResourcesParams & Parse): Promise<T[]>;
  requestAll(params: RequestAllResourcesParams & NoParse): Promise<P[]>;
  requestAll(params: RequestAllResourcesParams): Promise<T[] | P[]>;
  async requestAll(params: RequestAllResourcesParams): Promise<T[] | P[]> {
    const { parse = true, query } = params;
    const data: P[] = await super.requestAll(params);

    if (parse) {
      return data.map((obj) => new this.model({ ...obj, requestParams: { query } }));
    }

    return data;
  }

  create(body: P, params?: ResourceRequestParams & Parse): Promise<T>;
  create(body: P, params: ResourceRequestParams & NoParse): Promise<P>;
  create(body: P, params: ResourceRequestParams): Promise<T | P>;
  create(body: P, params?: ResourceRequestParams): Promise<T | P> {
    return this.request({
      ...params,
      path: this.getPath(),
      method: 'POST',
      body: this.stringify(body)
    });
  }

  get(id: I, params?: ResourceRequestParams & Parse): Promise<T>;
  get(id: I, params: ResourceRequestParams & NoParse): Promise<P>;
  get(id: I, params: ResourceRequestParams): Promise<T | P>;
  get(id: I, params?: ResourceRequestParams): Promise<T | P> {
    return this.request({
      ...params,
      path: this.getPath(id),
      method: 'GET'
    });
  }

  getAll(params?: ResourceRequestParams & Parse): Promise<T[]>;
  getAll(params: ResourceRequestParams & NoParse): Promise<P[]>;
  getAll(params: ResourceRequestParams): Promise<T[] | P[]>;
  getAll(params: ResourceRequestParams = {}): Promise<T[] | P[]> {
    return this.requestAll({ ...params, path: this.getPath() });
  }

  reload(obj: T): Promise<T> {
    return this.get(obj.id, obj.requestParams);
  }

  update(obj: T, params?: ResourceRequestParams & Parse): Promise<T>;
  update(obj: T, params: ResourceRequestParams & NoParse): Promise<P>;
  update(obj: T, params: ResourceRequestParams): Promise<T | P>;
  update(obj: T, params: ResourceRequestParams | undefined = obj.requestParams): Promise<T | P> {
    if (obj.id == null) {
      throw new Error('Cannot update object without an ID');
    }

    return this.request({
      ...params,
      path: this.getPath(obj.id),
      method: 'PUT',
      body: this.stringify(obj.toDictionary())
    });
  }

  async delete(obj: T, params?: ResourceRequestParams & Parse): Promise<T>;
  async delete(obj: T, params: ResourceRequestParams & NoParse): Promise<P>;
  async delete(obj: T, params: ResourceRequestParams): Promise<T | P>;
  async delete(obj: T, params: ResourceRequestParams | undefined = obj.requestParams): Promise<T | P> {
    if (obj.id == null) {
      throw new Error('Cannot update object without an ID');
    }

    return this.request({
      ...params,
      path: this.getPath(obj.id),
      method: 'DELETE'
    });
  }
}
