import { auditClient } from 'clients/AuditClient';
import { NoParse, Parse, ResourceRequestParams } from 'clients/ResourceClient';
import { StringIndexResourceClient } from 'clients/StringIndexResourceClient';
import { transactionClient } from 'clients/TransactionClient';
import { config } from 'Config';
import { Audit, AuditProps } from 'models/Audit';
import { Transaction, TransactionProps } from 'models/Transaction';
import { User, UserProps } from 'models/User';

export class UserClient extends StringIndexResourceClient<User, UserProps> {
  constructor() {
    super(User, 'users');
  }

  getCurrentUser(params?: ResourceRequestParams & Parse): Promise<User>;
  getCurrentUser(params: ResourceRequestParams & NoParse): Promise<UserProps>;
  getCurrentUser(params: ResourceRequestParams): Promise<User | UserProps>;
  getCurrentUser(params?: ResourceRequestParams): Promise<User | UserProps> {
    return this.request({ ...params, path: this.getPath('current') });
  }

  createTransaction(
    userId: string,
    body: TransactionProps,
    params?: ResourceRequestParams & Parse
  ): Promise<Transaction>;
  createTransaction(
    userId: string,
    body: TransactionProps,
    params: ResourceRequestParams & NoParse
  ): Promise<TransactionProps>;
  createTransaction(
    userId: string,
    body: TransactionProps,
    params: ResourceRequestParams
  ): Promise<Transaction | TransactionProps>;
  createTransaction(
    userId: string,
    body: TransactionProps,
    params?: ResourceRequestParams
  ): Promise<Transaction | TransactionProps> {
    return transactionClient.request({
      ...params,
      method: 'POST',
      path: this.getPath(`${userId}/${transactionClient.getPath()}`),
      body: this.stringify(body)
    });
  }

  getTransactions(userId: string, params?: ResourceRequestParams & Parse): Promise<Transaction[]>;
  getTransactions(userId: string, params: ResourceRequestParams & NoParse): Promise<TransactionProps[]>;
  getTransactions(userId: string, params: ResourceRequestParams): Promise<Transaction[] | TransactionProps[]>;
  getTransactions(userId: string, params?: ResourceRequestParams): Promise<Transaction[] | TransactionProps[]> {
    return transactionClient.requestAll({
      ...params,
      path: this.getPath(`${userId}/${transactionClient.getPath()}`)
    });
  }

  getAudits(userId: string, params?: ResourceRequestParams & Parse): Promise<Audit[]>;
  getAudits(userId: string, params: ResourceRequestParams & NoParse): Promise<AuditProps[]>;
  getAudits(userId: string, params: ResourceRequestParams): Promise<Audit[] | AuditProps[]>;
  getAudits(userId: string, params?: ResourceRequestParams): Promise<Audit[] | AuditProps[]> {
    return auditClient.requestAll({
      ...params,
      path: this.getPath(`${userId}/${auditClient.getPath()}`)
    });
  }

  updateEmail(id: string, password: string, newEmail: string, params?: ResourceRequestParams & Parse): Promise<User>;
  updateEmail(
    id: string,
    password: string,
    newEmail: string,
    params: ResourceRequestParams & NoParse
  ): Promise<UserProps>;
  updateEmail(id: string, password: string, newEmail: string, params: ResourceRequestParams): Promise<User | UserProps>;
  updateEmail(
    id: string,
    password: string,
    newEmail: string,
    params?: ResourceRequestParams
  ): Promise<User | UserProps> {
    return this.request({
      path: this.getPath(`${id}/email`),
      method: 'POST',
      body: this.stringify({ password: password, email: newEmail }),
      ...params
    });
  }

  verifyEmail(
    id: string,
    emailConfirmationTokenId: number,
    token: string,
    params?: ResourceRequestParams & Parse
  ): Promise<User>;
  verifyEmail(
    id: string,
    emailConfirmationTokenId: number,
    token: string,
    params?: ResourceRequestParams & NoParse
  ): Promise<UserProps>;
  verifyEmail(
    id: string,
    emailConfirmationTokenId: number,
    token: string,
    params: ResourceRequestParams
  ): Promise<User | UserProps>;
  verifyEmail(
    id: string,
    emailConfirmationTokenId: number,
    token: string,
    params?: ResourceRequestParams
  ): Promise<User | UserProps> {
    return this.request({
      path: this.getPath(`${id}/verify-email/${emailConfirmationTokenId}`),
      method: 'POST',
      body: this.stringify({ token: token }),
      ...params
    });
  }

  updatePassword(
    id: string,
    currentPassword: string,
    newPassword: string,
    params?: ResourceRequestParams & Parse
  ): Promise<User>;
  updatePassword(
    id: string,
    currentPassword: string,
    newPassword: string,
    params?: ResourceRequestParams & NoParse
  ): Promise<UserProps>;
  updatePassword(
    id: string,
    currentPassword: string,
    newPassword: string,
    params: ResourceRequestParams
  ): Promise<User | UserProps>;
  updatePassword(
    id: string,
    currentPassword: string,
    newPassword: string,
    params?: ResourceRequestParams
  ): Promise<User | UserProps> {
    return this.request({
      path: this.getPath(`${id}/password`),
      method: 'POST',
      body: this.stringify({ currentPassword: currentPassword, newPassword: newPassword }),
      ...params
    });
  }

  updateAvatar(id: string, avatar: File, params?: ResourceRequestParams & Parse): Promise<User>;
  updateAvatar(id: string, avatar: File, params?: ResourceRequestParams & NoParse): Promise<UserProps>;
  updateAvatar(id: string, avatar: File, params: ResourceRequestParams): Promise<User | UserProps>;
  updateAvatar(id: string, avatar: File, params?: ResourceRequestParams): Promise<User | UserProps> {
    const data = new FormData();
    data.append('avatar', avatar, 'avatar');

    return this.request({
      path: this.getPath(`${id}/avatar`),
      method: 'POST',
      body: data,
      options: {
        headers: {
          // including the content type breaks the API
          // 'Content-Type': 'multipart/form-data',
          Authorization: config.authToken
        }
      },
      ...params
    });
  }

  restore(user: User, params?: ResourceRequestParams & Parse): Promise<User>;
  restore(user: User, params: ResourceRequestParams & NoParse): Promise<UserProps>;
  restore(user: User, params: ResourceRequestParams): Promise<User | UserProps>;
  restore(user: User, params: ResourceRequestParams | undefined = user.requestParams): Promise<User | UserProps> {
    if (user.id == null) {
      throw new Error('Cannot update object without an ID');
    }

    return this.request({ ...params, method: 'POST', path: this.getPath(`${user.id}/restore`) });
  }
}

export const userClient = new UserClient();
