import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@material-ui/core';
import React from 'react';

export interface AvatarProps extends MuiAvatarProps {
  size?: number;
}

export function Avatar(props: AvatarProps): React.ReactElement {
  const { size = 6, ...avatarProps } = props;
  const sizePx = size * 8;
  const style = { width: sizePx, height: sizePx };

  return <MuiAvatar style={style} {...avatarProps} />;
}
