import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { AvatarName } from 'components/AvatarName';
import { UserDialog } from 'components/dialogs/UserDialog';
import { Table, TableProps } from 'components/Table';
import { Show } from 'components/visibility/Show';
import { LeaderboardUser } from 'models/LeaderboardUser';
import { User } from 'models/User';
import React, { useState } from 'react';

export interface LeaderboardProps extends TableProps {
  users: Array<LeaderboardUser>;
  label: string;
  currentUser?: User;
}

export function Leaderboard(props: LeaderboardProps): React.ReactElement {
  const { users, label, currentUser = new User(), ...tableProps } = props;
  const [userId, setUserId] = useState<string | null>();
  const isSupervisor = currentUser.hasAccessLevel('supervisor');

  return (
    <>
      <Table {...tableProps}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>User</TableCell>
            <TableCell>{label}</TableCell>
            <Show when={isSupervisor}>
              <TableCell padding="checkbox" />
            </Show>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow
              key={user.id}
              hover={isSupervisor}
              onClick={() => {
                if (isSupervisor) {
                  setUserId(user.id);
                }
              }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <AvatarName user={user} size={4} useDisplayName />
              </TableCell>
              <TableCell>{user.value.toFixed(0)}</TableCell>
              <Show when={isSupervisor}>
                <TableCell padding="checkbox">
                  <ChevronRight />
                </TableCell>
              </Show>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <UserDialog onClose={() => setUserId(null)} open={userId != null} userId={userId} />
    </>
  );
}
