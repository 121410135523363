import { DialogContent, Divider, Grid } from '@material-ui/core';
import { userClient } from 'clients/UserClient';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { TableList } from 'components/TableList';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { handleRequest } from 'RequestHelpers';

export interface UserDialogProps extends DialogProps {
  userId?: string | null;
}

export function UserDialog(props: UserDialogProps): React.ReactElement {
  const { userId, ...dialogProps } = props;
  const { onClose } = dialogProps;
  const snackbar = useSnackbar();
  const [user, setUser] = useState(new User());

  useEffect(() => {
    handleRequest(async () => {
      let user: User;

      if (userId) {
        user = await userClient.get(userId, { query: { include: 'role,currentTransaction' } });
      } else {
        user = new User();
      }

      setUser(user);
    }, snackbar).then();
  }, [userId]);

  return (
    <Dialog {...dialogProps}>
      <DialogCloseTitle onClose={onClose}>{user.name}</DialogCloseTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TableList
              data={[
                { label: 'Display Name', value: user.displayName },
                { label: 'Email', value: user.email },
                { label: 'Role', value: user.role.name },
                { label: 'Balance', value: user.balance }
              ]}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
