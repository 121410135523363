import { Button, ButtonGroup } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

// ..., quarter -1 = last quarter, quarter 0 = current quarter, quarter 1 = next quarter, ...

export function quarterToDateRange(
  quarter: number,
  allowFuture = true
): { startDate: DateTime; endDate: DateTime | null } {
  const startDate = DateTime.local().startOf('quarter').plus({ quarters: quarter });
  const endDate = !allowFuture && quarter === 0 ? null : startDate.endOf('quarter');
  return { startDate: startDate, endDate: endDate };
}

export interface DateQuery {
  after: string;
  before?: string;
}

export function quarterDateQuery(quarter: number, allowFuture = true): DateQuery {
  const { startDate, endDate } = quarterToDateRange(quarter, allowFuture);
  const query: DateQuery = { after: startDate.toISODate() as string };
  if (endDate) {
    query.before = endDate.toISODate() as string;
  }

  return query;
}

export interface CreatedAtQuery {
  createdAfter: string;
  createdBefore?: string;
}

export function quarterCreatedAtQuery(quarter: number, allowFuture = false): CreatedAtQuery {
  const { startDate, endDate } = quarterToDateRange(quarter, allowFuture);
  const query: CreatedAtQuery = { createdAfter: startDate.toISODate() as string };
  if (endDate) {
    query.createdBefore = endDate.toISODate() as string;
  }

  return query;
}

export function quarterDateString(quarter: number): string {
  return DateTime.local().plus({ quarters: quarter }).toFormat('Qq yyyy');
}

export function quarterMonthRangeString(quarter: number): string {
  const start = DateTime.local().plus({ quarters: quarter }).startOf('quarter');
  const end = start.endOf('quarter');
  const format = { month: 'short' };

  return `${start.toLocaleString(format)} - ${end.toLocaleString(format)}`;
}

export interface QuarterSelectorProps {
  quarter: number;
  onQuarterChange: (quarter: number) => any;
  changeDelay?: number;
  disableFuture?: boolean;
}

export function QuarterSelector(props: QuarterSelectorProps): React.ReactElement {
  const { quarter, onQuarterChange, changeDelay = 750, disableFuture = false } = props;
  const [currentQuarter, setCurrentQuarter] = useState(quarter);
  const [instant, setInstant] = useState(false);

  useEffect(() => {
    setCurrentQuarter(disableFuture && quarter > 0 ? 0 : quarter);
  }, [quarter, disableFuture]);

  useEffect(() => {
    if (instant) {
      onQuarterChange(currentQuarter);
      setInstant(false);
    } else {
      const timeout = setTimeout(() => {
        onQuarterChange(currentQuarter);
      }, changeDelay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [currentQuarter]);

  function setQuarterInstant(newQuarter: number): void {
    setInstant(true);
    setCurrentQuarter(newQuarter);
  }

  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          setCurrentQuarter(currentQuarter - 1);
        }}
      >
        <NavigateBefore />
      </Button>
      <Button onClick={() => setQuarterInstant(0)}>{`${quarterDateString(currentQuarter)} (${quarterMonthRangeString(
        currentQuarter
      )})`}</Button>
      <Button
        disabled={disableFuture && currentQuarter >= 0}
        onClick={() => {
          setCurrentQuarter(currentQuarter + 1);
        }}
      >
        <NavigateNext />
      </Button>
    </ButtonGroup>
  );
}
