import { Button, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { authenticationClient } from 'clients/AuthenticationClient';
import { Card } from 'components/Card';
import { CenteredContainer } from 'components/CenteredContainer';
import { config } from 'Config';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateConfirmPassword, validatePassword } from 'FormHelpers';
import { TextField } from 'mui-rff';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import { handleRequestError } from 'RequestHelpers';
import { useQuery } from 'Utils';

interface ResetPasswordData {
  password?: string;
  confirmPassword?: string;
}
interface ValidResetPasswordData {
  password: string;
  confirmPassword: string;
}

export function ResetPassword(): React.ReactElement {
  const { id } = useParams();
  const query = useQuery();
  const history = useHistory();
  const snackbar = useSnackbar();

  useEffect(() => {
    document.title = `${config.appName} Reset Password`;
  }, []);

  function notFoundAction(key: SnackbarKey): React.ReactElement {
    function handleClick(): void {
      history.push('/forgot-password');
      snackbar.closeSnackbar(key);
    }

    return <Button onClick={handleClick}>Request New Token</Button>;
  }

  async function onSubmit(data: ValidResetPasswordData): Promise<void> {
    try {
      await authenticationClient.resetPassword(id, query.get('token') || '', data.password);
      snackbar.enqueueSnackbar('Password Reset', { variant: 'success' });
      history.push('/login');
    } catch (e) {
      handleRequestError(e, snackbar, {
        404: {
          message: 'Password reset token not found',
          autoHideDuration: 10000,
          action: notFoundAction
        }
      });
    }
  }

  function validate(data: ResetPasswordData): ValidationErrors {
    const { password, confirmPassword } = data;

    return cleanErrors({
      password: validatePassword(password),
      confirmPassword: validateConfirmPassword(confirmPassword, password)
    });
  }

  return (
    <CenteredContainer>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors, submitting }) => (
          <Card>
            <CardHeader title="Reset Password" />
            <Divider />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField name="password" type="password" label="Password" autoFocus />
                  </Grid>
                  <Grid item>
                    <TextField name="confirmPassword" type="password" label="Confirm Password" />
                  </Grid>
                </Grid>
                <button className="d-none" type="submit" />
              </form>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button onClick={handleSubmit} disabled={hasValidationErrors || submitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}
      />
    </CenteredContainer>
  );
}
