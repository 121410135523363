import { DateTime } from 'luxon';
import { Model } from 'models/Model';
import { NumberIndexModel, NumberIndexModelProps } from 'models/NumberIndexModel';
import { StringIndexModel } from 'models/StringIndexModel';
import { TransactionTemplate, TransactionTemplateProps } from 'models/TransactionTemplate';
import { User, UserProps } from 'models/User';

export interface TransactionProps extends NumberIndexModelProps {
  userId?: string;
  createdById?: string;
  templateId?: number;
  correctsId?: number | null;
  date?: string | DateTime;
  amount?: number;
  balance?: number;
  comment?: string;
  user?: User | UserProps;
  createdBy?: User | UserProps;
  template?: TransactionTemplate | TransactionTemplateProps | null;
  corrects?: Transaction | TransactionProps | null;
  correctedBy?: Transaction | TransactionProps | null;
}

export class Transaction extends NumberIndexModel<TransactionProps> {
  userId = StringIndexModel.DEFAULT_ID;
  createdById = StringIndexModel.DEFAULT_ID;
  templateId = NumberIndexModel.DEFAULT_ID;
  correctsId: number | null = null;
  correctedById: number | null = null;
  amount = 0;
  balance = 0;
  comment = '';

  private _date: DateTime = Model.DEFAULT_DATE_TIME;
  get date(): DateTime {
    return this._date;
  }
  set date(value: DateTime) {
    this._date = value;
  }

  private _user!: User;
  get user(): User {
    return this._user;
  }
  set user(value: User) {
    this._user = value;
  }

  private _createdBy!: User;
  get createdBy(): User {
    return this._createdBy;
  }
  set createdBy(value: User) {
    this._createdBy = value;
  }

  private _template!: TransactionTemplate;
  get template(): TransactionTemplate {
    return this._template;
  }
  set template(value: TransactionTemplate) {
    this._template = value;
  }

  private _corrects: Transaction | null = null;
  get corrects(): Transaction | null {
    return this._corrects;
  }
  set corrects(value: Transaction | null) {
    this._corrects = value;
  }

  private _correctedBy: Transaction | null = null;
  get correctedBy(): Transaction | null {
    return this._correctedBy;
  }
  set correctedBy(value: Transaction | null) {
    this._correctedBy = value;
  }

  constructor(props: TransactionProps = {}) {
    super();
    this.init(props);
  }

  fromDictionary(props: TransactionProps): void {
    super.fromDictionary(props);

    if (props.date) {
      if (typeof props.date === 'string') {
        this.date = DateTime.fromISO(props.date, { zone: 'utc' });
      } else {
        this.date = props.date;
      }
    }

    if (props.user instanceof User) {
      this.user = props.user;
    } else {
      this.user = new User(props.user || {});
    }

    if (props.createdBy instanceof User) {
      this.createdBy = props.createdBy;
    } else {
      this.createdBy = new User(props.createdBy || {});
    }

    if (props.template instanceof TransactionTemplate) {
      this.template = props.template;
    } else {
      this.template = new TransactionTemplate(
        props.template || { name: 'Redeemed Points', description: 'Worker redeemed points', requiresComment: true }
      );
    }

    if (props.corrects == null) {
      this.corrects = null;
    } else if (props.corrects instanceof Transaction) {
      this.corrects = props.corrects;
    } else {
      this.corrects = new Transaction(props.corrects || {});
    }

    if (props.correctedBy == null) {
      this.correctedBy = null;
    } else if (props.correctedBy instanceof Transaction) {
      this.correctedBy = props.correctedBy;
    } else {
      this.correctedBy = new Transaction(props.correctedBy || {});
    }
  }

  toDictionary(): TransactionProps {
    const props = super.toDictionary();

    props.date = this.date.toISODate() as string;

    return props;
  }
}
