import { IconButton, InputAdornment, InputProps, TextField, TextFieldProps } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import React from 'react';

export type SearchTextFieldProps = TextFieldProps & { onClear?: () => any };

export function SearchTextField(props: SearchTextFieldProps): React.ReactElement {
  const { onClear, ...textFieldProps } = props;
  const inputProps: InputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <Search />
      </InputAdornment>
    )
  };

  if (onClear) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton onClick={onClear}>
          <Close />
        </IconButton>
      </InputAdornment>
    );
  }

  return <TextField InputProps={inputProps} {...textFieldProps} />;
}
