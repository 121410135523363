import { Client, RequestParams } from 'clients/Client';

export class OrganizationStatsClient extends Client {
  async getPointsAwarded(params: RequestParams): Promise<number> {
    return (await this.request({ path: 'organizations/points/awarded', method: 'GET', ...params })).value;
  }

  async getPointsRedeemed(params: RequestParams): Promise<number> {
    return (await this.request({ path: 'organizations/points/redeemed', method: 'GET', ...params })).value;
  }

  async getPointsBalance(params: RequestParams): Promise<number> {
    return (await this.request({ path: 'organizations/points/balance', method: 'GET', ...params })).value;
  }

  async getAuditAverage(params: RequestParams): Promise<number> {
    return (await this.request({ path: 'organizations/audits/average', method: 'GET', ...params })).value;
  }
}

export const organizationStatsClient = new OrganizationStatsClient();
