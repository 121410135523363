import { Model, ModelProps } from 'models/Model';

export type NumberIndexModelProps = ModelProps<number>;

export abstract class NumberIndexModel<P extends NumberIndexModelProps> extends Model<number, P> {
  static DEFAULT_ID = -1;
  id = NumberIndexModel.DEFAULT_ID;

  get isNew(): boolean {
    return this.id === NumberIndexModel.DEFAULT_ID;
  }
}
