import './index.css';

import { App } from 'components/views/App';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={1} disableWindowBlurListener={true} autoHideDuration={4000}>
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
let servivceWorkerUpdated = false;

serviceWorkerRegistration.register({
  autoUpdateInterval: 10 * 60000, // 10 minutes
  onUpdate: (swReg) => {
    servivceWorkerUpdated = true;
    swReg.waiting?.postMessage({ type: 'SKIP_WAITING' });
  },
  onActivated: () => {
    // Only reload on an update, not when registering the initial service worker
    if (servivceWorkerUpdated) window.location.reload();
  }
});
