import { Button, DialogActions, DialogContent, Divider, Grid, MenuItem } from '@material-ui/core';
import { userClient } from 'clients/UserClient';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateEmail, validateMinLength, validateRequired } from 'FormHelpers';
import { DropzoneArea } from 'material-ui-dropzone';
import { Role } from 'models/Role';
import { User, UserProps } from 'models/User';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { handleRequest } from 'RequestHelpers';

interface AddUserDialogProps extends DialogProps {
  currentUser: User;
  onAdd?: (user: User) => any;
}

export function AddUserDialog(props: AddUserDialogProps): React.ReactElement {
  const { currentUser, onAdd, ...dialogProps } = props;
  const { onClose } = dialogProps;
  const snackbar = useSnackbar();
  const [avatar, setAvatar] = useState<File | null>(null);
  const roles = Role.accessibleBy(currentUser.role);

  async function onSubmit(data: UserProps): Promise<void> {
    await handleRequest(async () => {
      let user = await userClient.create(data, { query: { include: 'role' } });
      if (avatar) {
        try {
          user = await userClient.updateAvatar(user.id, avatar, user.requestParams);
        } catch (e) {
          snackbar.enqueueSnackbar('Failed to upload avatar', { variant: 'error' });
        }
      } else {
        snackbar.enqueueSnackbar('User Added', { variant: 'success' });
      }
      if (onAdd) onAdd(user);
      if (onClose) onClose({}, 'backdropClick');
    }, snackbar);
  }

  function validate(data: UserProps): ValidationErrors {
    return cleanErrors({
      name: validateRequired(data.name) || validateMinLength(data.name, 3),
      email: validateEmail(data.email),
      roleId: validateRequired(data.roleId)
    });
  }

  return (
    <Dialog {...dialogProps}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ roleId: 'user' }}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors, submitting }) => (
          <>
            <DialogCloseTitle onClose={onClose}>Add User</DialogCloseTitle>
            <Divider />
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField name="name" label="Name" autoFocus />
                  </Grid>
                  <Grid item>
                    <TextField name="email" label="Email" />
                  </Grid>
                  <Grid item>
                    <TextField select name="roleId" label="Role">
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <b>Avatar</b>
                  </Grid>
                  <Grid item>
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={['image/*']}
                      onChange={(files) => setAvatar(files[0])}
                    />
                  </Grid>
                </Grid>
                <button className="d-none" type="submit" />
              </form>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={hasValidationErrors || submitting}
              >
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
}
