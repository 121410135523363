import React from 'react';

export interface HideProps extends React.PropsWithChildren<any> {
  when: boolean | null | undefined;
}

export function Hide(props: HideProps): React.ReactElement | null {
  const { when, children } = props;

  if (when) {
    return null;
  }

  return children;
}
