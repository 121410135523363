import { StringIndexModel, StringIndexModelProps } from 'models/StringIndexModel';

export interface RoleProps extends StringIndexModelProps {
  name?: string;
  description?: string;
  accessLevel?: number;
}

export class Role extends StringIndexModel<RoleProps> {
  private static _all: Array<Role> = [];
  static get all(): Array<Role> {
    return this._all;
  }
  static set all(value: Array<Role>) {
    this._all = value;
    this.map = value.reduce((obj, role) => {
      obj[role.id] = role;
      return obj;
    }, {} as Dictionary<Role>);
  }
  static map: Dictionary<Role> = {};

  static accessibleBy(role: Role): Array<Role> {
    const comparator = role.hasAccessLevel('admin')
      ? (r: Role) => role.accessLevel >= r.accessLevel
      : (r: Role) => role.accessLevel > r.accessLevel;
    return this.all.filter(comparator);
  }

  name = '';
  description = '';
  accessLevel = 0;

  constructor(data: Dictionary = {}) {
    super();
    this.init(data);
  }

  hasAccessLevel(otherRole: string): boolean {
    const other = Role.map[otherRole];
    return !!other && this.accessLevel >= other.accessLevel;
  }
}
