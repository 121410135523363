import { Checkbox, FormControlLabel, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { transactionTemplateClient } from 'clients/TransactionTemplateClient';
import { TemplateDialog } from 'components/dialogs/TransactionTemplateDialog';
import { Table } from 'components/Table';
import { TableView } from 'components/TableView';
import { Show } from 'components/visibility/Show';
import { TransactionTemplate } from 'models/TransactionTemplate';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { handleRequest } from 'RequestHelpers';
import { findById } from 'Utils';

export function TransactionTemplates(): React.ReactElement {
  const snackbar = useSnackbar();
  const [templates, setTemplates] = useState<Array<TransactionTemplate>>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [showDiscarded, setShowDiscarded] = useState(false);

  const keptTemplates = templates.filter((template) => template.kept);
  const discardedTemplates = templates.filter((template) => template.discarded);

  useEffect(() => {
    handleRequest(async () => {
      try {
        setTemplates(await transactionTemplateClient.getAll({ query: { all: true } }));
      } finally {
        setLoading(false);
      }
    }, snackbar).then();
  }, []);

  function onDialogClose(): void {
    setTemplateDialogOpen(false);
    setSelectedTemplateId(null);
  }

  function onCreate(template: TransactionTemplate): void {
    const newTemplates = templates.slice();
    newTemplates.unshift(template);
    setTemplates(newTemplates);
  }

  function onUpdate(template: TransactionTemplate): void {
    const newTemplates = templates.slice();
    newTemplates.splice(newTemplates.findIndex(findById(template.id)), 1, template);
    setTemplates(newTemplates);
  }

  return (
    <TableView
      title="Point Categories"
      data={templates}
      loading={loading}
      onFabClick={() => setTemplateDialogOpen(true)}
    >
      <FormControlLabel
        label="Show Disabled"
        control={
          <Checkbox color="primary" checked={showDiscarded} onChange={(e) => setShowDiscarded(e.target.checked)} />
        }
      />
      <Table stickyToolbar>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Requires Comment</TableCell>
            <TableCell padding="checkbox" />
          </TableRow>
        </TableHead>
        <TableBody>
          {keptTemplates.map((template) => (
            <TableRow key={template.id} hover onClick={() => setSelectedTemplateId(template.id)}>
              <TableCell>{template.name}</TableCell>
              <TableCell>{template.description}</TableCell>
              <TableCell>{template.value}</TableCell>
              <TableCell>{template.requiresComment ? 'Yes' : 'No'}</TableCell>
              <TableCell padding="checkbox">
                <ChevronRight />
              </TableCell>
            </TableRow>
          ))}
          <Show when={showDiscarded}>
            <TableRow>
              <TableCell colSpan={100}>
                <b>Disabled Categories</b>
              </TableCell>
            </TableRow>
            {discardedTemplates.map((template) => (
              <TableRow key={template.id} hover onClick={() => setSelectedTemplateId(template.id)}>
                <TableCell>{template.name}</TableCell>
                <TableCell>{template.description}</TableCell>
                <TableCell>{template.value}</TableCell>
                <TableCell>{template.requiresComment ? 'Yes' : 'No'}</TableCell>
                <TableCell padding="checkbox">
                  <ChevronRight />
                </TableCell>
              </TableRow>
            ))}
          </Show>
        </TableBody>
      </Table>

      <TemplateDialog
        onClose={onDialogClose}
        open={templateDialogOpen || selectedTemplateId != null}
        templateId={selectedTemplateId}
        onCreate={onCreate}
        onUpdate={onUpdate}
      />
    </TableView>
  );
}
