import { Button, DialogActions, DialogContent, Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import { transactionTemplateClient } from 'clients/TransactionTemplateClient';
import { userClient } from 'clients/UserClient';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { Hide } from 'components/visibility/Hide';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateInt, validateMaxLength, validateMin, validateRequired } from 'FormHelpers';
import { DateTime } from 'luxon';
import { NumberIndexModel } from 'models/NumberIndexModel';
import { TransactionProps } from 'models/Transaction';
import { TransactionTemplate } from 'models/TransactionTemplate';
import { User } from 'models/User';
import { Checkboxes, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { handleRequestError } from 'RequestHelpers';
import { closeDialog, findById } from 'Utils';

interface AddTransactionData {
  redeeming: boolean;
  amountText?: string;
  templateIdText?: string;
  dateText?: string;
  comment?: string;
}

interface ValidAddTransactionData {
  redeeming: boolean;
  templateId: number;
  template: TransactionTemplate;
  amount: number;
  newBalance: number;
  date: DateTime;
  comment?: string;
}

export interface AddTransactionDialogProps extends DialogProps {
  user: User;
  onChange?: () => any;
}

export function AddTransactionDialog(props: AddTransactionDialogProps): React.ReactElement {
  const snackbar = useSnackbar();
  const { user, onChange, ...dialogProps } = props;
  const { onClose } = dialogProps;
  const [templates, setTemplates] = useState<Array<TransactionTemplate>>([]);
  const commentMaxLength = 200;

  useEffect(() => {
    (async () => {
      try {
        setTemplates(await transactionTemplateClient.getAll({ query: { onlyKept: true } }));
      } catch (e) {
        handleRequestError(e, snackbar);
      }
    })();
  }, []);

  if (user == null) {
    return <></>;
  }

  function transformValues(data: AddTransactionData): ValidAddTransactionData {
    const { redeeming, amountText = '0', templateIdText, dateText, comment } = data;

    const date = dateText ? DateTime.fromISO(dateText) : DateTime.local();
    const templateId =
      parseInt(templateIdText || NumberIndexModel.DEFAULT_ID.toString()) || NumberIndexModel.DEFAULT_ID;
    const template = templates.find(findById(templateId)) || new TransactionTemplate();
    const amount = (redeeming ? -Number.parseInt(amountText) : template.value) || 0;
    const newBalance = user.balance + amount;

    return { redeeming, templateId, template, date, amount, newBalance, comment };
  }

  async function onSubmit(values: AddTransactionData): Promise<void> {
    const { redeeming, templateId, amount, date, comment } = transformValues(values);
    const transactionData: TransactionProps = { comment, date: date.toISODate() as string };

    if (redeeming) {
      transactionData.amount = amount;
    } else {
      transactionData.templateId = templateId;
    }

    try {
      await userClient.createTransaction(user.id, transactionData);
      snackbar.enqueueSnackbar('Transaction Created', { variant: 'success' });
      if (onChange) onChange();
      closeDialog(onClose);
    } catch (e) {
      handleRequestError(e, snackbar);
    }
  }

  function validate(values: AddTransactionData): ValidationErrors {
    const { dateText, templateIdText, amountText } = values;
    const { redeeming, template, newBalance, comment } = transformValues(values);
    const errors: ValidationErrors = {
      dateText: validateRequired(dateText),
      comment:
        ((template.requiresComment || redeeming) && validateRequired(comment)) ||
        validateMaxLength(comment, commentMaxLength)
    };
    let balanceError: string | undefined;

    if (newBalance < 0) {
      balanceError = 'New balance cannot be less than zero';
    }

    if (redeeming) {
      errors.amountText =
        validateRequired(amountText) || validateInt(amountText) || validateMin(amountText, 1) || balanceError;
    } else {
      errors.templateIdText = validateRequired(templateIdText) || balanceError;
    }

    return cleanErrors(errors);
  }

  return (
    <Dialog {...dialogProps}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{ dateText: DateTime.local().toISODate() }}
        render={({ handleSubmit, values, hasValidationErrors, submitting }) => {
          const { redeeming, template, comment, newBalance } = transformValues(values);

          return (
            <>
              <DialogCloseTitle onClose={onClose}>Add Transaction for {user.name}</DialogCloseTitle>
              <Divider />
              <DialogContent>
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <TextField name="dateText" label="Transaction Date" type="date" />
                    </Grid>
                    <Grid item>
                      <Checkboxes name="redeeming" color="primary" data={{ label: 'Redeem Points', value: true }} />
                    </Grid>
                    {redeeming ? (
                      <TextField name="amountText" label="Amount" type="number" required={true} />
                    ) : (
                      <Grid item>
                        <TextField select name="templateIdText" label="Category">
                          {templates.map((template) => (
                            <MenuItem value={template.id} key={template.id}>
                              {template.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    <Grid item>
                      <Grid container spacing={1}>
                        <Hide when={redeeming}>
                          <Grid item>
                            <Typography className="no-wrap" component="span">
                              Amount: {template.value || 0}
                            </Typography>
                          </Grid>
                        </Hide>
                        <Grid item>
                          <Typography className="no-wrap" component="span">
                            New Balance: {newBalance}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TextField
                        name="comment"
                        label="Comment"
                        required={redeeming || template.requiresComment}
                        multiline
                        helperText={comment ? `${comment?.length || 0}/${commentMaxLength}` : ''}
                      />
                    </Grid>
                  </Grid>
                  <button className="d-none" type="submit" />
                </form>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={hasValidationErrors || submitting}
                >
                  Save
                </Button>
              </DialogActions>
            </>
          );
        }}
      />
    </Dialog>
  );
}
