import { Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Fab } from 'components/Fab';
import { Heading } from 'components/Heading';
import { LoadableContainer, LoadableContainerProps } from 'components/LoadableContainer';
import React from 'react';

export interface TableViewProps<T> extends LoadableContainerProps<T> {
  title: string;
  onFabClick?: () => any;
  showFab?: boolean;
}

export function TableView<T>(props: TableViewProps<T>): React.ReactElement {
  const { title, onFabClick, showFab = onFabClick != null, ...loadableContainerProps } = props;

  return (
    <>
      <Grid container direction={'column'} spacing={2}>
        <Grid item>
          <Heading level={1}>{title}</Heading>
        </Grid>
        <Grid item>
          <LoadableContainer {...loadableContainerProps} />
        </Grid>
      </Grid>

      {showFab && onFabClick ? (
        <Fab onClick={onFabClick}>
          <Add />
        </Fab>
      ) : null}
    </>
  );
}
