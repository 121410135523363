import {
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { Add, ChevronRight, Search } from '@material-ui/icons';
import { aggregateClient } from 'clients/AggregateClient';
import { userClient } from 'clients/UserClient';
import { AvatarName } from 'components/AvatarName';
import { AddTransactionDialog } from 'components/dialogs/AddTransactionDialog';
import { AddUserDialog } from 'components/dialogs/AddUserDialog';
import { UserDialog } from 'components/dialogs/UserDialog';
import { LoadableContainer } from 'components/LoadableContainer';
import { quarterDateQuery, QuarterSelector } from 'components/QuarterSelector';
import { SearchTextField } from 'components/SearchTextField';
import { Table } from 'components/Table';
import { TableView } from 'components/TableView';
import { Hide } from 'components/visibility/Hide';
import { Show } from 'components/visibility/Show';
import { config } from 'Config';
import { DateTime } from 'luxon';
import { LeaderboardUser } from 'models/LeaderboardUser';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { abortEffect, handleRequest } from 'RequestHelpers';
import { findById } from 'Utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      marginLeft: theme.spacing(1)
    },
    nameSearch: {
      display: 'inline-block',
      width: '20vw',
      maxWidth: theme.spacing(25)
    },
    balance: {
      display: 'inline-block',
      minWidth: theme.spacing(5)
    },
    disabledText: {
      minHeight: '48px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center'
    }
  })
);

class UserEntry {
  constructor(public user: User, public auditAverage?: number | null, public pointsAwarded?: number | null) {}
}

const sortFunctions: Dictionary<(a: UserEntry, b: UserEntry) => number> = {
  name: (entry1: UserEntry, entry2: UserEntry) => entry1.user.name.localeCompare(entry2.user.name),
  email: (entry1: UserEntry, entry2: UserEntry) => entry1.user.email.localeCompare(entry2.user.email),
  role: (entry1: UserEntry, entry2: UserEntry) => entry1.user.roleId.localeCompare(entry2.user.roleId),
  lastAudit: (entry1: UserEntry, entry2: UserEntry) =>
    (entry1.user.latestAudit?.date.toMillis() || 0) - (entry2.user.latestAudit?.date.toMillis() || 0),
  auditAverage: (entry1: UserEntry, entry2: UserEntry) => (entry1.auditAverage || 0) - (entry2.auditAverage || 0),
  pointsAwarded: (entry1: UserEntry, entry2: UserEntry) => (entry1.pointsAwarded || 0) - (entry2.pointsAwarded || 0),
  balance: (entry1: UserEntry, entry2: UserEntry) =>
    (entry1.user.currentTransaction?.balance || 0) - (entry2.user.currentTransaction?.balance || 0)
};

function filterName(userEntries: UserEntry[], searchName: string): UserEntry[] {
  const trimmedSearch = searchName.trim().replace(/\s{2,}/g, ' ');

  if (trimmedSearch.length === 0) {
    return userEntries;
  }

  const searchTokens = trimmedSearch
    .split(' ')
    .map((str) => str.toLowerCase())
    .filter((str, index, arr) => arr.indexOf(str) === index);

  return userEntries.filter((entry) => searchTokens.every((token) => entry.user.name.toLowerCase().includes(token)));
}

export interface UsersProps {
  currentUser: User;
}

export function Users(props: UsersProps): React.ReactElement {
  const { currentUser } = props;
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [users, setUsers] = useState<User[]>([]);
  const [userEntries, setUserEntries] = useState<UserEntry[]>([]);
  const [allKeptEntries, setAllKeptEntries] = useState<UserEntry[]>([]);
  const [allDiscardedEntries, setAllDiscardedEntries] = useState<UserEntry[]>([]);
  const [keptEntries, setKeptEntries] = useState<UserEntry[]>([]);
  const [discardedEntries, setDiscardedEntries] = useState<UserEntry[]>([]);
  const [quarter, setQuarter] = useState<number>(0);
  const [auditAverages, setAuditAverages] = useState<LeaderboardUser[]>([]);
  const [pointsAwarded, setPointsAwarded] = useState<LeaderboardUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalsLoading, setTotalsLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [addingTransaction, setAddingTransaction] = useState(false);
  const [addingUser, setAddingUser] = useState(false);
  const [showDiscarded, setShowDiscarded] = useState(false);
  const [sortKey, setSortKey] = useState<string | null>();
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const isAdmin = currentUser.hasAccessLevel('admin');

  const allKeptUsers = users.filter((user) => user.kept);
  const allDiscardedUsers = users.filter((user) => user.discarded);

  useEffect(() => {
    document.title = `${config.appName} Users`;

    return abortEffect((signal) => {
      handleRequest(async () => {
        const users = await userClient.getAll({
          query: { include: 'role,currentTransaction,latestAudit', all: true },
          signal
        });
        setUsers(users.sort((user1, user2) => user1.name.localeCompare(user2.name)));
        setLoading(false);
      }, snackbar);
    });
  }, []);

  useEffect(() => {
    setUserEntries(
      users.map((user) => {
        const auditAverage = auditAverages.find(findById(user.id))?.value;
        const points = pointsAwarded.find(findById(user.id))?.value;
        return new UserEntry(user, auditAverage, points);
      })
    );
  }, [users, auditAverages, pointsAwarded]);

  useEffect(() => {
    setAllKeptEntries(userEntries.filter((entry) => entry.user.kept));
    setAllDiscardedEntries(userEntries.filter((entry) => entry.user.discarded));
  }, [userEntries]);

  function processEntries(entries: UserEntry[]): UserEntry[] {
    const sortFunction = sortFunctions[sortKey || ''];
    entries = entries.slice();

    if (searching) {
      entries = filterName(entries, searchName);
    }

    if (sortFunction) {
      entries.sort(sortFunction);

      if (sortDirection === 'desc') {
        entries.reverse();
      }
    }

    return entries;
  }

  useEffect(() => {
    setKeptEntries(processEntries(allKeptEntries));
  }, [allKeptEntries, searching, searchName, sortKey, sortDirection]);

  useEffect(() => {
    setDiscardedEntries(processEntries(allDiscardedEntries));
  }, [allDiscardedEntries, searching, searchName, sortKey, sortDirection]);

  useEffect(() => {
    if (selectedUser == null) setAddingTransaction(false);
  }, [selectedUser]);

  useEffect(() => {
    setTotalsLoading(true);
    const query = quarterDateQuery(quarter);

    return abortEffect((signal) => {
      const auditsPromise = aggregateClient.getAuditsLeaderboard({ query, signal });
      const pointsPromise = aggregateClient.getPointsLeaderboard({ query, signal });

      handleRequest(async () => {
        setAuditAverages(await auditsPromise);
        setPointsAwarded(await pointsPromise);
        setTotalsLoading(false);
      }, snackbar);
    });
  }, [quarter]);

  const keptUsers = allKeptUsers;
  const discardedUsers = allDiscardedUsers;

  async function handleUserChange(): Promise<void> {
    if (selectedUser) {
      const index = users.findIndex(findById(selectedUser.id));
      const newUsers = [...users];

      newUsers[index] = await userClient.reload(selectedUser);
      setUsers(newUsers);
    }
  }

  function sortOn(key: string): void {
    if (key === sortKey) {
      if (sortDirection === 'desc') {
        setSortKey(null);
        setSortDirection('asc');
      } else {
        setSortDirection('desc');
      }
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  }

  function renderRow({ user, auditAverage, pointsAwarded }: UserEntry): React.ReactNode {
    const accountUrl = user.id === currentUser.id ? '/account' : `${url}/${user.id}`;

    return (
      <TableRow
        key={user.id}
        hover
        onClick={() => {
          if (isAdmin) {
            history.push(accountUrl);
          } else {
            setSelectedUser(user);
          }
        }}
      >
        <TableCell>
          <AvatarName user={user} size={5} />
        </TableCell>
        <TableCell>{user.email}</TableCell>
        <Show when={isAdmin}>
          <TableCell>{user.role.name}</TableCell>
        </Show>
        <TableCell>{user.latestAudit?.date.toLocaleString(DateTime.DATE_FULL) || 'Never'}</TableCell>
        <TableCell>
          <LoadableContainer loading={totalsLoading} inline size={20}>
            {auditAverage?.toFixed(2) || '-'}
          </LoadableContainer>
        </TableCell>
        <TableCell>
          <LoadableContainer loading={totalsLoading} inline size={20}>
            {pointsAwarded || '-'}
          </LoadableContainer>
        </TableCell>
        <TableCell className="no-wrap">
          <div className={classes.balance}>{user.balance}</div>
          <IconButton
            onClick={(e) => {
              setAddingTransaction(true);
              setSelectedUser(user);
              e.stopPropagation();
            }}
            disabled={user.discarded || user.id === currentUser.id}
          >
            <Add />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <ChevronRight />
        </TableCell>
      </TableRow>
    );
  }

  const hasData = keptUsers.length + discardedUsers.length !== 0;

  return (
    <>
      <TableView
        title="Users"
        loading={loading}
        hasData={hasData}
        showFab={isAdmin}
        onFabClick={() => setAddingUser(true)}
      >
        <Grid container spacing={2}>
          <Grid item>
            <QuarterSelector quarter={quarter} onQuarterChange={setQuarter} />
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Show Disabled"
              control={
                <Checkbox
                  color="primary"
                  checked={showDiscarded}
                  onChange={(e) => setShowDiscarded(e.target.checked)}
                />
              }
            />
          </Grid>
        </Grid>

        <Table stickyToolbar lessPadding>
          <TableHead>
            <TableRow>
              <TableCell>
                {searching ? (
                  <div className={classes.nameSearch}>
                    <SearchTextField
                      label="Name"
                      fullWidth
                      autoFocus
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      onClear={() => setSearching(false)}
                    />
                  </div>
                ) : (
                  <div className="no-wrap">
                    Name
                    <IconButton className={classes.iconButton} onClick={() => setSearching(true)}>
                      <Search />
                    </IconButton>
                  </div>
                )}
              </TableCell>
              {[
                { label: 'Email', sortKey: 'email' },
                { label: 'Role', sortKey: 'role', hide: !isAdmin },
                { label: 'Last Audit', sortKey: 'lastAudit', noWrap: true },
                { label: 'Audit Average', sortKey: 'auditAverage' },
                { label: 'Points Awarded', sortKey: 'pointsAwarded' },
                { label: 'Balance', sortKey: 'balance' }
              ].map((cell, index) => {
                if (cell.hide) {
                  return null;
                }

                let result: ReactNode = cell.label;

                if (cell.noWrap) {
                  result = <span className="no-wrap">{result}</span>;
                }

                if (cell.sortKey) {
                  result = (
                    <TableSortLabel
                      active={sortKey === cell.sortKey}
                      direction={sortKey === cell.sortKey ? sortDirection : 'asc'}
                      onClick={() => sortOn(cell.sortKey)}
                    >
                      {result}
                    </TableSortLabel>
                  );
                }

                return <TableCell key={index}>{result}</TableCell>;
              })}
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <Hide when={loading}>
            <TableBody>
              {keptEntries.map(renderRow)}
              <Show when={showDiscarded && hasData}>
                <TableRow>
                  <TableCell colSpan={100}>
                    <div className={classes.disabledText}>Disabled Users</div>
                  </TableCell>
                </TableRow>
                {discardedEntries.map(renderRow)}
              </Show>
            </TableBody>
          </Hide>
        </Table>
      </TableView>

      <AddTransactionDialog
        user={selectedUser || new User()}
        open={addingTransaction && selectedUser != null}
        onChange={handleUserChange}
        onClose={() => setSelectedUser(null)}
      />
      <UserDialog
        userId={selectedUser?.id}
        open={!addingTransaction && selectedUser != null}
        onChange={handleUserChange}
        onClose={() => setSelectedUser(null)}
      />
      <AddUserDialog
        open={addingUser}
        onClose={() => {
          setAddingUser(false);
        }}
        currentUser={currentUser}
        onAdd={(user: User) => {
          const newUsers = users.slice();
          newUsers.unshift(user);
          setUsers(newUsers);
        }}
      />
    </>
  );
}
