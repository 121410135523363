import { Button, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';
import { transactionClient } from 'clients/TransactionClient';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { TableList } from 'components/TableList';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateMaxLength } from 'FormHelpers';
import { DateTime } from 'luxon';
import { Transaction } from 'models/Transaction';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { handleRequest, handleRequestError } from 'RequestHelpers';
import { closeDialog } from 'Utils';

interface CorrectTransactionData {
  comment?: string;
}

export interface CorrectTransactionDialogProps extends DialogProps {
  transactionId?: number | null;
  onChange?: () => any;
}

export function CorrectTransactionDialog(props: CorrectTransactionDialogProps): React.ReactElement {
  const snackbar = useSnackbar();
  const { transactionId, onChange, ...dialogProps } = props;
  const [transaction, setTransaction] = useState(new Transaction());
  const { onClose } = dialogProps;
  const maxCommentLength = 200;

  useEffect(() => {
    handleRequest(async () => {
      let transaction: Transaction;

      if (transactionId) {
        transaction = await transactionClient.get(transactionId, { query: { include: 'template,user,createdBy' } });
      } else {
        transaction = new Transaction();
      }

      setTransaction(transaction);
    }, snackbar).then();
  }, [transactionId]);

  async function onSubmit(values: CorrectTransactionData): Promise<void> {
    const { comment } = values;

    try {
      await transactionClient.correctTransaction(transaction.id, { comment: comment });
      snackbar.enqueueSnackbar('Transaction Created', { variant: 'success' });
      if (onChange) onChange();
      closeDialog(onClose);
    } catch (e) {
      handleRequestError(e, snackbar);
    }
  }

  function validate(values: CorrectTransactionData): ValidationErrors {
    const { comment } = values;

    return cleanErrors({
      comment: validateMaxLength(comment, maxCommentLength)
    });
  }

  return (
    <Dialog {...dialogProps} maxWidth="sm">
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values, hasValidationErrors, submitting }) => {
          const { comment } = values;
          const tableData = [
            {
              label: 'Created At',
              value: transaction.createdAt.toLocaleString(DateTime.DATETIME_FULL)
            },
            {
              label: 'Date',
              value: transaction.createdAt.toLocaleString(DateTime.DATE_FULL)
            },
            { label: 'User', value: transaction.user.name },
            { label: 'Amount', value: transaction.amount },
            { label: 'Created By', value: transaction.createdBy.name }
          ];

          return (
            <>
              <DialogCloseTitle onClose={onClose}>Correct Transaction</DialogCloseTitle>
              <Divider />
              <DialogContent>
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>Are you sure you want to correct this transaction?</Grid>
                    <Grid item>
                      <TableList data={tableData} />
                    </Grid>
                    <Grid item>
                      This will create a correction transaction in the amount of{' '}
                      <b>
                        {-transaction.amount} point{transaction.amount ** 2 === 1 ? '' : 's'}
                      </b>
                      .
                    </Grid>
                    <Grid item>
                      <TextField
                        name="comment"
                        label="Comment"
                        multiline
                        helperText={comment ? `${comment?.length || 0}/${maxCommentLength}` : ''}
                      />
                    </Grid>
                  </Grid>
                  <button className="d-none" type="submit" />
                </form>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button color="primary" onClick={() => closeDialog(onClose)}>
                  No
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={hasValidationErrors || submitting}
                >
                  Yes
                </Button>
              </DialogActions>
            </>
          );
        }}
      />
    </Dialog>
  );
}
