import { NumberIndexResourceClient } from 'clients/NumberIndexResourceClient';
import { Transaction, TransactionProps } from 'models/Transaction';

import { NoParse, Parse, ResourceRequestParams } from './ResourceClient';

export class TransactionClient extends NumberIndexResourceClient<Transaction, TransactionProps> {
  constructor() {
    super(Transaction, 'transactions');
  }

  correctTransaction(
    transactionId: number,
    body: TransactionProps,
    params?: ResourceRequestParams & Parse
  ): Promise<Transaction>;
  correctTransaction(
    transactionId: number,
    body: TransactionProps,
    params: ResourceRequestParams & NoParse
  ): Promise<TransactionProps>;
  correctTransaction(
    transactionId: number,
    body: TransactionProps,
    params: ResourceRequestParams
  ): Promise<Transaction | TransactionProps>;
  correctTransaction(
    transactionId: number,
    body: TransactionProps,
    params?: ResourceRequestParams
  ): Promise<Transaction | TransactionProps> {
    return this.request({
      ...params,
      method: 'POST',
      path: this.getPath(`${transactionId}/correct`),
      body: this.stringify(body)
    });
  }
}

export const transactionClient = new TransactionClient();
