import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Table } from 'components/Table';
import React from 'react';

export interface TableListItem {
  label: string;
  value: React.ReactNode;
}

export interface TableListProps {
  data?: TableListItem[] | null;
}

export function TableList(props: TableListProps): React.ReactElement {
  const { data } = props;

  if (data == null) {
    return <></>;
  }

  return (
    <Table>
      <TableBody>
        {data.map((obj) => (
          <TableRow key={obj.label}>
            <TableCell>
              <b>{obj.label}</b>
            </TableCell>
            <TableCell>{obj.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
