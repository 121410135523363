class Config {
  appName = 'AWI Safetify';
  tokenStorageKey = 'awi-safetify-auth-token';
  persistLoginStorageKey = 'awi-safetify-persist-login';

  get apiBaseUrl(): string {
    return process.env['REACT_APP_SAFETIFY_API_URL'] || '';
  }

  get persistLogin(): boolean {
    return (localStorage.getItem(this.persistLoginStorageKey) || '') === 'true';
  }

  set persistLogin(value: boolean) {
    localStorage.setItem(this.persistLoginStorageKey, value.toString());
  }

  get authToken(): string {
    return this.tokenStore.getItem(this.tokenStorageKey) || '';
  }

  set authToken(value: string) {
    this.tokenStore.setItem(this.tokenStorageKey, value);
  }

  private get tokenStore(): Storage {
    return this.persistLogin ? localStorage : sessionStorage;
  }

  clearLogin(): void {
    for (const storage of [sessionStorage, localStorage]) {
      storage.removeItem(this.tokenStorageKey);
    }
  }
}

export const config = new Config();
