import { RequestError } from 'clients/RequestError';
import { config } from 'Config';
import { OptionsObject, ProviderContext, SnackbarMessage } from 'notistack';

export type RequestErrorHandlerCallback = () => any;

export interface SnackbarOptions extends OptionsObject {
  message?: SnackbarMessage | null;
  skipSnackbar?: boolean | null;
  callback?: RequestErrorHandlerCallback | null;
}

export function wasAborted(error: Error): boolean {
  return error.name === 'AbortError';
}

export function handleRequestError(
  error: any,
  snackbar: ProviderContext,
  options: Record<number | string, SnackbarOptions> = {},
  defaultOptions: SnackbarOptions = { disableWindowBlurListener: true }
): void {
  if (wasAborted(error)) {
    return;
  }

  let snackbarOptions: SnackbarOptions = { message: 'Error processing request', ...defaultOptions };

  if (error instanceof RequestError) {
    snackbarOptions = { ...snackbarOptions, ...options[error.status] };
  } else {
    console.error(error);
  }

  if (snackbarOptions.callback) {
    snackbarOptions.callback();
  } else if (!snackbarOptions?.skipSnackbar) {
    snackbar.enqueueSnackbar(snackbarOptions.message, { variant: 'error', ...defaultOptions, ...snackbarOptions });
  }
}

export function handleUnauthorizedError(
  error: any,
  history: any,
  snackbar: ProviderContext,
  options: Record<number, SnackbarOptions> = {},
  defaultOptions: SnackbarOptions = {}
): void {
  // Exclude paths we don't want to redirect to after logging in
  const location = window.location;
  const path = `${location.pathname}${location.hash}${location.search}`;
  const pathQuery = location.pathname === '/logout' || path === '/' ? '' : `?redirect=${path}`;

  handleRequestError(
    error,
    snackbar,
    {
      401: {
        skipSnackbar: true,
        callback: () => {
          config.authToken = '';
          history.push(`/login${pathQuery}`);
        }
      },
      ...options
    },
    defaultOptions
  );
}

export async function handleRequest<T>(
  requestFunction: () => Promise<T>,
  snackbar: ProviderContext,
  options: Record<number, SnackbarOptions> = {},
  defaultOptions: SnackbarOptions = {}
): Promise<T | null> {
  try {
    return await requestFunction();
  } catch (e) {
    handleRequestError(e, snackbar, options, defaultOptions);
  }

  return null;
}

// Returned inside a useEffect callback to cancel in-flight requests by
// forwarding the signal passed to the callback to requests
export function abortEffect(callback: (signal: AbortSignal) => any): () => void {
  const controller = new AbortController();

  callback(controller.signal);

  return () => {
    controller.abort();
  };
}
