import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core';
import { authenticationClient } from 'clients/AuthenticationClient';
import { Card } from 'components/Card';
import { config } from 'Config';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Link, useHistory } from 'react-router-dom';
import { handleRequestError } from 'RequestHelpers';
import { useQuery } from 'Utils';

export function Login(): React.ReactElement {
  const history = useHistory();
  const query = useQuery();
  const snackbar = useSnackbar();
  const [mountComplete, setMountComplete] = useState(false);
  const [persistLogin, setPersistLogin] = useState(config.persistLogin);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function redirect(): void {
    history.push(query.get('redirect') || '/');
  }

  useEffect(() => {
    document.title = `${config.appName} Login`;
    if (config.authToken) redirect();
    setMountComplete(true);
  }, []);

  useEffect(() => {
    config.persistLogin = persistLogin;
  }, [persistLogin]);

  if (!mountComplete) {
    return <></>;
  }

  async function handleSubmit(): Promise<void> {
    try {
      await authenticationClient.login(email, password);
      redirect();
    } catch (e) {
      handleRequestError(e, snackbar, {
        401: { message: 'Incorrect email or password' }
      });
    }
  }

  return (
    <Grid container className="vh-100" justify="center" alignItems="center">
      <Grid item xs>
        <Container maxWidth="xs">
          <Card>
            <CardHeader title="AWI Safetify Login" />
            <Divider />
            <CardContent>
              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <TextField
                          label="Email"
                          name="email"
                          type="email"
                          autoComplete="username"
                          fullWidth
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          fullWidth
                          onChange={(e) => setPassword(e.target.value)}
                          helperText={<Link to="/forgot-password">Forgot Password?</Link>}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          label="Keep me logged in"
                          control={
                            <Checkbox
                              color="primary"
                              checked={persistLogin}
                              onChange={(e) => setPersistLogin(e.target.checked)}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    <button className="d-none" type="submit" />
                  </form>
                )}
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button onClick={handleSubmit}>Submit</Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Container>
      </Grid>
    </Grid>
  );
}
