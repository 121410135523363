import { Button, DialogActions, DialogContent, Divider, Grid, Typography } from '@material-ui/core';
import { transactionClient } from 'clients/TransactionClient';
import { Dialog, DialogProps } from 'components/dialogs/Dialog';
import { DialogCloseTitle } from 'components/dialogs/DialogCloseTitle';
import { ErrorButton } from 'components/ErrorButton';
import { TableList } from 'components/TableList';
import { Hide } from 'components/visibility/Hide';
import { Show } from 'components/visibility/Show';
import { DateTime } from 'luxon';
import { Transaction } from 'models/Transaction';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { handleRequest } from 'RequestHelpers';

export interface TransactionDialogProps extends DialogProps {
  transactionId: number | null | undefined;
  onTransactionIdChange: (id: number) => any;
  currentUser?: User | null;
  onCorrect?: (transactionId: number) => void;
}

export function TransactionDialog(props: TransactionDialogProps): React.ReactElement {
  const { transactionId, currentUser, onCorrect, ...dialogProps } = props;
  const snackbar = useSnackbar();
  const [transaction, setTransaction] = useState(new Transaction());

  async function setTransactionFromId(id?: number | null): Promise<void | null> {
    return handleRequest(async () => {
      let transaction: Transaction;

      if (id) {
        transaction = await transactionClient.get(id, {
          query: { include: 'user,createdBy,template,correctedBy' }
        });
      } else {
        transaction = new Transaction();
      }

      setTransaction(transaction);
    }, snackbar);
  }

  useEffect(() => {
    setTransactionFromId(transactionId).then();
  }, [transactionId]);

  const canCorrect =
    onCorrect &&
    currentUser?.hasAccessLevel('supervisor') &&
    transaction.correctsId == null &&
    !transaction.correctedBy;

  return (
    <Dialog {...dialogProps}>
      <DialogCloseTitle onClose={dialogProps.onClose}>Transaction</DialogCloseTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          {[
            {
              hideWhen: transaction.correctsId == null,
              text: 'This transaction is a correction.',
              viewText: 'View original transaction',
              viewAction: () => setTransactionFromId(transaction.correctsId)
            },
            {
              hideWhen: transaction.correctedBy == null,
              text: 'This transaction has been corrected.',
              viewText: 'View correction transaction',
              viewAction: () => setTransactionFromId(transaction.correctedBy?.id)
            }
          ].map((data, index) => (
            <Hide when={data.hideWhen} key={index}>
              <Grid item container direction="column" spacing={1}>
                <Grid item>{data.text}</Grid>
                <Grid item>
                  <Button color="primary" variant="outlined" onClick={data.viewAction}>
                    {data.viewText}
                  </Button>
                </Grid>
              </Grid>
            </Hide>
          ))}
          <Grid item>
            <TableList
              data={[
                {
                  label: 'Created At',
                  value: transaction.createdAt.toLocaleString(DateTime.DATETIME_FULL)
                },
                {
                  label: 'Date',
                  value: transaction.date.toLocaleString(DateTime.DATE_FULL)
                },
                { label: 'User', value: transaction.user.name },
                { label: 'Category', value: transaction.template.name },
                { label: 'Amount', value: transaction.amount },
                { label: 'New Balance', value: transaction.balance },
                { label: 'Created By', value: transaction.createdBy.name }
              ]}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography>
                  <b>Comment</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{transaction.comment}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Show when={canCorrect}>
        <Divider />
      </Show>
      <DialogActions>
        <Show when={canCorrect}>
          <ErrorButton
            variant="contained"
            disabled={currentUser?.id === transaction.userId}
            onClick={() => {
              if (onCorrect) {
                onCorrect(transaction.id);
              }
            }}
          >
            Correct
          </ErrorButton>
        </Show>
      </DialogActions>
    </Dialog>
  );
}
