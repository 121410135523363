import { DialogTitle, DialogTitleProps, Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { closeDialog } from 'Utils';

export interface DialogCloseTitleProps extends DialogTitleProps {
  onClose: DialogCloseFunction;
}

export function DialogCloseTitle(props: DialogCloseTitleProps): React.ReactElement {
  const { onClose, children, ...titleProps } = props;

  return (
    <DialogTitle {...titleProps}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>{children}</Grid>
        <Grid item>
          <IconButton onClick={() => closeDialog(onClose)}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
}
