import { Client } from 'clients/Client';
import { config } from 'Config';
import { User } from 'models/User';

export class AuthenticationClient extends Client {
  async login(email: string, password: string): Promise<User> {
    config.clearLogin();

    return new User(
      await this._request('login', {
        method: 'POST',
        body: this.stringify({ email: email, password: password })
      })
    );
  }

  async logout(): Promise<void> {
    await this._request('logout', { method: 'POST' });
    config.persistLogin = false;
    config.clearLogin();
  }

  acceptInvitation(id: number, token: string, password: string): Promise<void> {
    return this._request(`accept-invitation/${id}`, {
      method: 'POST',
      body: this.stringify({ password: password, token: token })
    });
  }

  requestPasswordReset(email: string): Promise<void> {
    return this._request('request-password-reset', {
      method: 'POST',
      body: this.stringify({ email: email })
    });
  }

  resetPassword(id: number, token: string, password: string): Promise<void> {
    return this._request(`reset-password/${id}`, {
      method: 'POST',
      body: this.stringify({ password: password, token: token })
    });
  }
}

export const authenticationClient = new AuthenticationClient();
