import { Button, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { authenticationClient } from 'clients/AuthenticationClient';
import { Card } from 'components/Card';
import { CenteredContainer } from 'components/CenteredContainer';
import { config } from 'Config';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateConfirmPassword, validatePassword } from 'FormHelpers';
import { TextField, TextFieldProps } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import { handleRequestError } from 'RequestHelpers';
import { useQuery } from 'Utils';

interface AcceptInvitationData {
  password?: string;
  confirmPassword?: string;
}

export function AcceptInvitation(): React.ReactElement {
  const history = useHistory();
  const query = useQuery();
  const snackbar = useSnackbar();
  const { id } = useParams();
  const token = query.get('token');

  useEffect(() => {
    document.title = `${config.appName} Create Account`;
  }, []);

  async function onSubmit(values: AcceptInvitationData): Promise<void> {
    const password = values.password as string;

    try {
      await authenticationClient.acceptInvitation(id, token || '', password);
      snackbar.enqueueSnackbar('Password Updated', { variant: 'success' });
      history.push('/login');
    } catch (e) {
      handleRequestError(e, snackbar);
    }
  }

  function validate(values: AcceptInvitationData): ValidationErrors {
    const { password, confirmPassword } = values;

    return cleanErrors({
      password: validatePassword(password),
      confirmPassword: validateConfirmPassword(confirmPassword, password)
    });
  }

  return (
    <>
      <CenteredContainer>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, hasValidationErrors, submitting }) => (
            <Card>
              <CardHeader title="Create Account" />
              <Divider />
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    {([
                      {
                        name: 'password',
                        type: 'password',
                        autoComplete: 'new-password',
                        label: 'Password'
                      },
                      {
                        name: 'confirmPassword',
                        type: 'password',
                        autoComplete: 'new-password',
                        label: 'Confirm Password'
                      }
                    ] as Array<TextFieldProps>).map((fieldProps, index) => (
                      <Grid item key={index}>
                        <TextField {...fieldProps} />
                      </Grid>
                    ))}
                  </Grid>
                  <button className="d-none" type="submit" />
                </form>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container direction="row-reverse">
                  <Grid item>
                    <Button onClick={handleSubmit} disabled={hasValidationErrors || submitting}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          )}
        />
      </CenteredContainer>
    </>
  );
}
