import { Button, CardActions, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { authenticationClient } from 'clients/AuthenticationClient';
import { Card } from 'components/Card';
import { CenteredContainer } from 'components/CenteredContainer';
import { Hide } from 'components/visibility/Hide';
import { Show } from 'components/visibility/Show';
import { config } from 'Config';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateEmail } from 'FormHelpers';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { handleRequestError } from 'RequestHelpers';

interface ForgotPasswordData {
  email?: string;
}
interface ValidForgotPasswordData {
  email: string;
}

export function ForgotPassword(): React.ReactElement {
  const snackbar = useSnackbar();
  const [submitted, setSubmitted] = React.useState(false);

  useEffect(() => {
    document.title = `${config.appName} Forgot Password`;
  }, []);

  async function onSubmit(data: ValidForgotPasswordData): Promise<void> {
    try {
      await authenticationClient.requestPasswordReset(data.email);
      setSubmitted(true);
    } catch (e) {
      handleRequestError(e, snackbar);
    }
  }

  function validate(data: ForgotPasswordData): ValidationErrors {
    return cleanErrors({ email: validateEmail(data.email) });
  }

  return (
    <>
      <Show when={submitted}>
        <CenteredContainer maxWidth="sm">
          <Card>
            <CardHeader title="Password Reset Email Sent" />
            <Divider />
            <CardContent>
              <Typography component="p">
                If the account exists, an email with instructions on how to reset your password has been sent to the
                provided email address.
              </Typography>
            </CardContent>
          </Card>
        </CenteredContainer>
      </Show>
      <Hide when={submitted}>
        <CenteredContainer>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, hasValidationErrors, submitting, errors }) => (
              <Card>
                <CardHeader title="Forgot Password" />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <TextField name="email" type="email" label="Email" autoFocus />
                      </Grid>
                    </Grid>
                    <button className="d-none" type="submit" />
                  </form>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid container direction="row-reverse">
                    <Grid item>
                      <Button onClick={handleSubmit} disabled={hasValidationErrors || submitting}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            )}
          />
        </CenteredContainer>
      </Hide>
    </>
  );
}
