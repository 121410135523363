import { AddEditAuditView } from 'components/views/Audits/AddEditAuditView';
import { AuditsView } from 'components/views/Audits/AuditsView';
import { AuditView } from 'components/views/Audits/AuditView';
import { User } from 'models/User';
import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

export interface AuditsWrapperProps {
  currentUser: User;
}

export function AuditsWrapper(props: AuditsWrapperProps): React.ReactElement {
  const { currentUser } = props;
  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <AuditsView />
      </Route>
      <Route path={`${path}/new`}>
        <AddEditAuditView onClose={() => history.push(path)} />
      </Route>
      <Route path={`${path}/:id`}>
        <AuditView currentUser={currentUser} />
      </Route>
    </Switch>
  );
}
