import { DateTime } from 'luxon';
import { Discardable, isKept } from 'models/Discardable';
import { Model } from 'models/Model';
import { NumberIndexModel, NumberIndexModelProps } from 'models/NumberIndexModel';

export interface TransactionTemplateProps extends NumberIndexModelProps {
  name?: string;
  description?: string;
  value?: number;
  requiresComment?: boolean;
  discardedAt?: string;
}

export class TransactionTemplate extends NumberIndexModel<TransactionTemplateProps> implements Discardable {
  name = '';
  description = '';
  value = 0;
  requiresComment = false;
  discardedAt = Model.DEFAULT_DATE_TIME;

  get kept(): boolean {
    return isKept(this);
  }
  get discarded(): boolean {
    return !this.kept;
  }

  constructor(data: Dictionary = {}) {
    super();
    this.init(data);
  }

  fromDictionary(props: TransactionTemplateProps): void {
    super.fromDictionary(props);
    this.discardedAt = DateTime.fromISO(props.discardedAt || Model.DEFAULT_DATE_TIME_STRING);
  }

  toDictionary(): TransactionTemplateProps {
    const props = super.toDictionary();
    props.discardedAt = this.discardedAt.toISO() as string;
    return props;
  }
}
