import { Grid } from '@material-ui/core';
import { aggregateClient } from 'clients/AggregateClient';
import { Leaderboard } from 'components/Leaderboard';
import { quarterDateQuery, QuarterSelector } from 'components/QuarterSelector';
import { TableView } from 'components/TableView';
import { config } from 'Config';
import { LeaderboardUser } from 'models/LeaderboardUser';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { abortEffect, handleRequest } from 'RequestHelpers';

export function PointsLeaderboardView(): React.ReactElement {
  const snackbar = useSnackbar();
  const [leaderboard, setLeaderboard] = useState<LeaderboardUser[] | null>();
  const [quarter, setQuarter] = useState<number>(0);

  useEffect(() => {
    document.title = `${config.appName} Points Leaderboard`;
  }, []);

  useEffect(() => {
    setLeaderboard(null);

    return abortEffect((signal) => {
      handleRequest(async () => {
        const leaderboard = await aggregateClient.getPointsLeaderboard({ query: quarterDateQuery(quarter), signal });
        setLeaderboard(leaderboard);
      }, snackbar);
    });
  }, [quarter]);

  return (
    <>
      <TableView title="Points Leaderboard" data={leaderboard}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <QuarterSelector quarter={quarter} onQuarterChange={setQuarter} />
          </Grid>
          <Grid item>
            <Leaderboard users={leaderboard || []} label="Points" stickyToolbar />
          </Grid>
        </Grid>
      </TableView>
    </>
  );
}
