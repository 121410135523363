import React from 'react';

export interface ShowProps extends React.PropsWithChildren<any> {
  when: boolean | null | undefined;
}

export function Show(props: ShowProps): React.ReactElement | null {
  const { when, children } = props;

  if (when) {
    return children;
  }

  return null;
}
