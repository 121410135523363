import { Model, ModelProps } from 'models/Model';

export type StringIndexModelProps = ModelProps<string>;

export abstract class StringIndexModel<P extends StringIndexModelProps> extends Model<string, P> {
  static DEFAULT_ID = '';
  id = StringIndexModel.DEFAULT_ID;

  get isNew(): boolean {
    return this.id === StringIndexModel.DEFAULT_ID;
  }
}
