import { Client, RequestParams } from 'clients/Client';
import { LeaderboardUser } from 'models/LeaderboardUser';

export class AggregateClient extends Client {
  async getPointsLeaderboard(params: RequestParams): Promise<LeaderboardUser[]> {
    const data = await this.requestAll({ path: 'aggregate/points', ...params });
    return data.map((d) => new LeaderboardUser(d));
  }

  async getPointTotal(userId: string, params: RequestParams): Promise<LeaderboardUser> {
    const data = await this.request({ path: `aggregate/points/${userId}`, ...params });
    return new LeaderboardUser(data);
  }

  async getAuditsLeaderboard(params: RequestParams): Promise<LeaderboardUser[]> {
    const data = await this.requestAll({ path: 'aggregate/audits', ...params });
    return data.map((d) => new LeaderboardUser(d));
  }

  async getAuditAverage(userId: string, params: RequestParams): Promise<LeaderboardUser> {
    const data = await this.request({ path: `aggregate/audits/${userId}`, ...params });
    return new LeaderboardUser(data);
  }
}

export const aggregateClient = new AggregateClient();
