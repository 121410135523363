import { Button, ButtonProps, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { themes } from 'Themes';

export function ErrorButton(props: ButtonProps): React.ReactElement {
  return (
    <ThemeProvider theme={themes.error}>
      <Button color="primary" {...props}>
        {props.children}
      </Button>
    </ThemeProvider>
  );
}
